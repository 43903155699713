import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClassViewComponent } from './components/User/class-view/class-view.component';
import { ProfileComponent } from './components/User/profile/profile.component';



const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'welcome' },
  { path: 'class/:user_id/:content_id/:memory', component: ClassViewComponent },

  {
    path: 'user',
    loadChildren: () =>
      import('./components/User/user-routing.module').then(
        (r) => r.UserRoutingModule
      ),
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./components/welcome-view/welcome-routing.module').then(
        (m) => m.WelcomeRoutingModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./components/Auth/auth-routing.module').then(
        (a) => a.AuthRoutingModule
      ),
  },
  {
    path: 'profile/:user_id',
    title: 'profile',
    component: ProfileComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
