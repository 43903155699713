<div
  class="flex flex-col items-center justify-center rounded-3xl"
  style="background-color: #22333b; height: 400px;"

>
<div class="text-white w-full h-full">
  <div class="flex justify-center h-1/3">
    <img [src]="content.image" alt="image" class="w-full h-full object-cover rounded-t-3xl" />
  </div>
  <div class="px-6 py-4 h-1/2">
    <div class="font-semibold text-sm mb-1 text-start">
      {{ content.title }}
    </div>
    <hr />
    <div class="text-sm mb-1 text-start">
      {{ content.description }}
    </div>
    <div class="text-sm mb-1 text-start">
      {{ 'Creado:' | translate }} {{ content.creationDate }}
    </div>
  </div>
  <div class="flex px-6 pb-4 h-1/6 justify-between">
    <div class="flex items-center rounded-full">
      <span *ngIf="content.resourcesStatus && content.resourcesStatus !== 'Done'" class="font-semibold text-xs px-3 py-1 bg-green-500 text-white rounded-full mr-2">
        {{ content.resourcesStatus }}
      </span>
      <i *ngIf="content.resourcesStatus && content.resourcesStatus !== 'Done'" class="fas fa-cog fa-spin text-green-500" style="margin-left: 5px;"></i>
    </div>


  <button *ngIf="isInvitation" class="bg-transparent hover:bg-[#c6ac8f] text-white rounded-full z-10 p-2 opacity-100 w-8 h-8" (click)="handleShare($event)" title="Compartir">
    🔗
  </button>

  <div *ngIf="!isInvitation">
    <button class="bg-transparent hover:bg-[#c6ac8f] text-white rounded-full z-10 p-2 opacity-100 w-8 h-8" (click)="handleShare($event)" title="Compartir">
      🔗
    </button>
    <button class="bg-transparent hover:bg-[#c6ac8f] text-white rounded-full z-10 p-2 opacity-100 w-8 h-8" (click)="testContent()" title="Ver Contenido">
      ⚡️
    </button>
    <button class="bg-transparent hover:bg-[#c6ac8f] text-white rounded-full z-10 p-2 opacity-100 w-8 h-8" (click)="showPopupView({'action':'editPresentation','contentId': content._id!})" title="Editar">
      🏞️
    </button>
    <button class="bg-transparent hover:bg-[#c6ac8f] text-white rounded-full z-10 p-2 opacity-100 w-8 h-8" (click)="deleteContent()" title="Eliminar">
      ❌
    </button>
  </div>



  </div>
</div>

</div>
