<div class="flex flex-col p-1 relative" style="height: 100vh; background-image: url('https://images.pexels.com/photos/17483871/pexels-photo-17483871/free-photo-of-an-artist-s-illustration-of-artificial-intelligence-ai-this-image-was-inspired-neural-networks-used-in-deep-learning-it-was-created-by-novoto-studio-as-part-of-the-visualising-ai-proje.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'); background-size: cover; background-position: center;">

  <div class="absolute inset-0 bg-light-grey opacity-85"></div>

  <div class="z-10">
    <div class="flex items-center justify-between p-4" style="height: 7vh;">
      <h1 class="text-4xl font-black text-dark-blue" style="font-family: Fredoka, sans-serif;">Learnable</h1>
      <div class="flex items-center">
        <div class="relative" (click)="toggleDropdown()">
          <ul>
            <li class="mb-2">
              <select class="form-select" (change)="ChangeLang($event)" id="languageSelect">
                <option [value]="'es'" [selected]="lang === 'es'">🌎{{ 'ES' | translate }}</option>
                <option [value]="'en'" [selected]="lang === 'en'">🌎{{ 'EN' | translate }}</option>
              </select>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="z-10 h-[93vh] flex items-start justify-center">
    <div class="w-full md:w-[50vw] h-auto md:h-[85vh] bg-beige rounded-xl flex flex-col justify-between p-5">
      <div>
        <h2 class="text-2xl font-semibold mb-4 text-center">{{'Perfil'|translate}}</h2>
        <div class="flex flex-col justify-center items-center w-full">
          <div class="text-center">
            <img class="h-24 w-24 md:h-32 md:w-32 rounded-full border-4 border-white mx-auto my-4" src="https://incubator.ucf.edu/wp-content/uploads/2023/07/artificial-intelligence-new-technology-science-futuristic-abstract-human-brain-ai-technology-cpu-central-processor-unit-chipset-big-data-machine-learning-cyber-mind-domination-generative-ai-scaled-1.jpg" alt="">
          </div>
          <form [formGroup]="formReg!" class="w-full">
            <div class="mt-4 md:mt-10 flex flex-col md:flex-row items-center">
              <label for="manySlides" class="w-full md:w-64 mr-0 md:mr-2.5 font-bold text-custom-brown">{{'Full name'|translate}}</label>
              <input type="text" id="manySlides" class="w-full md:flex-1 p-2.5 rounded-lg border border-gray-300 text-lg bg-light-grey" formControlName="slides">
            </div>
            <div class="mt-4 md:mt-10 flex flex-col md:flex-row items-center">
              <label for="speech" class="w-full md:w-64 mr-0 md:mr-2.5 font-bold text-custom-brown">{{'Email address'|translate}}</label>
              <input type="text" id="speech" class="w-full md:flex-1 p-2.5 rounded-lg border border-gray-300 text-lg bg-light-grey" formControlName="speech_len">
            </div>
          </form>
        </div>
      </div>
      <div class="flex flex-col w-full items-center mt-4 md:mt-0">
        <button class="bg-dark-blue text-white font-bold py-3 px-10 rounded-lg m-2 w-full">{{ 'Cambiar Contraseña'|translate}}</button>
        <button class="bg-[#EC6A6A] text-white font-bold py-3 px-10 rounded-lg m-2 w-full">{{ 'Cerrar Sesión'|translate }}</button>
      </div>
    </div>
  </div>
</div>
