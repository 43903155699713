import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Content } from 'src/app/model/content';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';
@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.css']
})
export class ContentCardComponent {

  @Input() isInvitation!: boolean;
  @Input() content!: Content;
  @Output() editContent = new EventEmitter<void>();
  @Output() shareContent = new EventEmitter<Event>();
  @Output() closePopup = new EventEmitter<void>();

  close() {this.closePopup.emit();}
  user_id: string| undefined;
constructor(private router: Router, private contentService: ContentService,
  private activatedRoute: ActivatedRoute){}

 ngOnInit(): void {

  this.activatedRoute.paramMap.subscribe((params) => {
    console.log("PARAMS:",params);
    this.user_id=params.get('user_id')!;
  });


 }
  handleEdit() {
    this.editContent.emit();
  }



  handleShare(event: Event) {
    this.shareContent.emit(event);
  }

  testContent() {
    console.log('🔎search');
    console.log(`Searching for course with ID: ${this.content._id!}`);
    this.router.navigate(['/class', "0",this.content._id! , false]);
  }

deleteContent() {
    this.contentService.deleteContent({"content_id": this.content._id!,"user_id":this.user_id!}).subscribe( (res) => {
      console.log('Content deleted');
      this.close();
    });
  }

  showPopupView(params?:any){
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      replaceUrl: true
    });
  }


}
