import { Component, Input } from '@angular/core';
import {  EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-shared-content',
  templateUrl: './shared-content.component.html',
  styleUrls: ['./shared-content.component.css']
})
export class SharedContentComponent {
  @Input() content_id: any;
  @Output() shareContentEvent = new EventEmitter<any>();

  shareContent() {
    this.shareContentEvent.emit(this.content_id);
  }
  showMessageBox: boolean = false;
  email: string = '';
  emails: Set<string> = new Set<string>();
  message: string = '';
  selectedTab: string = 'online';
  addEmail() {
    if (this.email.trim() !== '') {
      this.emails.add(this.email);
      this.showMessageBox = true;
      this.email = '';
      console.log(Array.from(this.emails))
    }
  }

  toggleMessageBox() {
    this.showMessageBox = !this.showMessageBox;
  }
  
  selectTab(tab: string): void {
    this.selectedTab = tab;
  }
  removeEmail(emailToRemove: string) {
    this.emails.delete(emailToRemove);
  }






  @Output() closeModalEvent = new EventEmitter<void>();
  currentUrl: string | undefined;


  constructor(private contentService: ContentService) {}

  ngOnInit(): void {
    this.currentUrl = `${window.location.protocol}//${window.location.host}/class/0/${this.content_id}/false`;
    console.log('Domain and Protocol:', this.currentUrl);
  }


  sendInvitationEmail(): void {
    console.log('Input value:', this.emails);
    this.contentService.sendInvitationEmail(Array.from(this.emails), this.currentUrl!,this.content_id).subscribe((response) => {
      console.log(response);
      this.email = '';
      this.emails.clear();
    });
  }

  copyShareUrl(): void {
    const textArea = document.createElement('textarea');
    textArea.value = this.currentUrl!;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    console.log('Share ID copied to clipboard');
  }

  closeModal() {
    this.closeModalEvent.emit();
  }


}
