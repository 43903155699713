<div class="popup-body">
  <div class="form-container">

    <h1>Edit Presentation 🖼️</h1>

    <h2>Slide {{this.index+1}} </h2>

    <form [formGroup]="formReg!">

      <div class="form-group">
          <label for="learning" class="label">Title</label>
          <input type="text" id="learning" class="input" placeholder="" formControlName="title">
      </div>
      <div class="form-group">
          <label for="learning" class="label">Subtitle</label>
          <input type="text" id="learning" class="input" placeholder="" formControlName="subtitle">
      </div>

      <div *ngIf="presentations_slides['slides_content'][index]?.image !=null" class="form-group">
        <label for="learning" class="label">Image</label>
        <input type="text" id="learning" class="input" placeholder="" formControlName="image">

        <div class="file-drop-area">
            <span class="fake-btn">Choose files</span>
            <span class="file-msg">or drag and drop files here</span>
            <input class="file-input" type="file" accept=".png, .jpg, .jpeg" (change)="onFileSelected($event)" multiple>
        </div>
    </div>


    <div class="form-group">
      <label for="learning" class="label">Content</label>
      <textarea type="text" id="learning" class="input" placeholder="" formControlName="content"></textarea>
  </div>

  </form>
  </div>


  <div class="upload-container">
    <div class="inner_html" [innerHTML]="presentationHTML"></div>
  </div>

</div>

<div class="button-container">
  <button (click)="updateContentResources()" class="save-button">Save</button>
</div>
