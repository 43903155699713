export const environment = {
  production: false,
  firebase: {
    projectId: 'boostlearnable',
    appId: '1:942939847857:web:07f4d4319e113b1f653690',
    storageBucket: 'boostlearnable.appspot.com',
    apiKey: 'AIzaSyCYnandYX6HCB7eClpjxsB62E5TQyjrWdU',
    authDomain: 'boostlearnable.firebaseapp.com',
    messagingSenderId: '942939847857',
    measurementId: 'G-DMGFSY7TRN',
  },
  rest_api: 'https://i6u5xcro82.execute-api.us-east-1.amazonaws.com/test',
  socket_api:'wss://mpsb3e6ey1.execute-api.us-east-1.amazonaws.com/test/'
};
