<div class="grid grid-cols-1 md:grid-cols-2 gap-5 overflow-y-auto h-[65vh] p-4">
  <div>
    <h1 class="text-left font-bold text-3xl text-[#22333B] mb-4">{{ 'Crear Guión Metodológico'|translate }} 📚</h1>
    <form [formGroup]="formReg!">
      <div class="mb-5">
        <label for="learningSelect" class="block md:w-64 mr-2.5 font-bold text-[#C6AC8F]">{{ 'Objetivo de Aprendizaje'|translate }}</label>
        <select id="learningSelect" class="w-full h-10 p-1 bg-[#e9e7dc] rounded-lg" (change)="onSelect($event)">
          <option *ngFor="let goal of learning_goal_templates" [value]="goal.description">{{ goal.title }}</option>
        </select>
        <textarea id="learningText" class="w-full h-32 p-4 mt-4 bg-[#e9e7dc] rounded-lg resize-none" formControlName="learning_goal"></textarea>
        <button class="block w-full mt-4 mb-4 p-2 text-lg text-white bg-[#C6AC8F] rounded cursor-pointer" (click)="createMethodScript()">{{ 'Crear guión metodológico'|translate }} ➡</button>
      </div>
    </form>
  </div>
  <div class="w-full p-4 border border-gray-300 rounded h-[60vh]">
    <ng-container *ngIf="loadingMethodScript; else content">
      <div class="flex flex-col items-center justify-center h-[90%]">
        <div class="relative w-16 h-16">
          <div class="absolute inset-0 w-16 h-16 border-6 border-blue-500 rounded-full animate-pulse"></div>
          <div class="absolute inset-0 w-16 h-16 border-6 border-transparent border-t-blue-500 rounded-full animate-spin"></div>
        </div>
        <div class="text-2xl mt-5 text-blue-500 font-sans uppercase text-center">{{ loadingMessage }}</div>
      </div>
    </ng-container>
    <ng-template #content>
      <textarea class="w-full h-full p-2.5 overflow-y-auto resize-none bg-[#e9e7dc] rounded-lg" [(ngModel)]="script"></textarea>
    </ng-template>
  </div>
</div>
<div class="flex flex-col h-full p-4">
  <div class="mt-auto">
    <button class="w-full px-8 py-4 bg-[#C6AC8F] text-white border-none cursor-pointer text-base rounded-lg" (click)="updateContentResources(); !validateForm()">{{ 'Siguiente'|translate }}</button>
  </div>
</div>


