import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  UserCredential,
  GoogleAuthProvider,
  AuthProvider,
  signInWithPopup,
} from '@angular/fire/auth';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, from, switchMap} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  REST_API= environment.rest_api;

  constructor(private auth: Auth, private http: HttpClient) {}

  register(formData: FormGroup): Observable<any> {
    return from(
      createUserWithEmailAndPassword(
        this.auth,
        formData.value['email'],
        formData.value['password']))
      .pipe(
        switchMap((userCredential: UserCredential) => {
          const endpoint = `${this.REST_API}/User/AddUser`;
          console.log(userCredential.user.uid);
          formData.addControl('id', new FormControl(userCredential.user.uid));
          formData.removeControl('password');
          formData.removeControl('confirm_password');
          return this.http.post(endpoint, formData.value);
        })
      );
  }



  login({ email, password }: any) {
    return signInWithEmailAndPassword(this.auth, email, password).then(
      (userCredential: UserCredential) => userCredential.user.uid
    );
  }

  singInWithGoogleProvider(): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();
    return this.callPopUp(provider);
  }

  async callPopUp(provider: AuthProvider): Promise<UserCredential> {
    try {
      const result = await signInWithPopup(this.auth, provider);
      return result;
    } catch (error: any) {
      return error;
    }
  }


  getUser(id: any): Observable<any> {
    const endpoint = `${this.REST_API}/User/GetUser?id=${id}`;
    return this.http.get<any>(endpoint, {});
  }

}
