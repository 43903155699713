<!-- Sidebar Button -->
<button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar"
  type="button"
  class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
  <span class="sr-only">Open sidebar</span>
  <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
    <path clip-rule="evenodd" fill-rule="evenodd"
      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
    </path>
  </svg>
</button>

<!-- Sidebar -->
<aside id="default-sidebar" class="fixed top-0 left-0 z-30 h-screen transition-transform -translate-x-full sm:translate-x-0" style="width: 260px"
  aria-label="Sidebar">
  <div class="h-full py-4 overflow-y-auto" style="background-color: #edebdf">
    <h1 class="text-4xl px-5 py-5 font-black self-start text-dark-blue" style="font-family: 'Fredoka', sans-serif;">Learnable</h1>
    <ul class="space-y-2 font-medium">
      <li>
        <a
          class="flex items-center px-1 py-3 text-gray-900 rounded-lg dark:hover:bg-custom-brown hover:text-light-grey group"
          (click)="showMisTutores = true; showTutoresCompartidos = false;">
          <span class="ms-3 text-3lg">🧠 {{ 'Mis Cursos'|translate }}</span>
        </a>
      </li>
      <li>
        <a
          class="flex items-center px-1 py-3 text-gray-900 rounded-lg dark:hover:bg-custom-brown hover:text-light-grey group"
          (click)="showMisTutores = false; showTutoresCompartidos = true;">
          <span class="flex-1 ms-3 text-3lg">🔗 {{ 'Cursos Compartidos'|translate }} <br> {{ 'Conmigo'|translate }}</span>
        </a>

      </li>
    </ul>
  </div>
</aside>



<!-- Main Content -->
<div class="py-5 sm:ml-64" style="background-color: #F4F3EE">
  <!-- Search Section -->
  <section class="flex items-center mb-1">
    <div class="max-w-screen-3xl px-4 mx-auto lg:px-8 w-full">
      <div class="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
        <div class="w-full md:w-1/2">
          <form class="flex items-center">
            <label for="simple-search" class="sr-only">Search</label>
            <div class="relative w-full">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                class="block w-full p-4 pl-10 text-sm border border-gray-300 rounded-3xl focus:ring-primary-500 focus:border-primary-500"
                placeholder="{{ 'Search'|translate }}"
                (keydown)="handleKeyDown($event)"
              />
            </div>
          </form>
        </div>

        <div class="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
          <div class="relative" (click)="toggleDropdown()">
            <ul>
              <li class="mb-2">
                <select class="form-select" (change)="ChangeLang($event)" id="languageSelect">
                  <option [value]="'es'" [selected]="lang === 'es'">🌎{{ 'ES' | translate }}</option>
                  <option [value]="'en'" [selected]="lang === 'en'">🌎{{ 'EN' | translate }}</option>
                </select>
              </li>
            </ul>
          </div>

          <div class="flex items-center w-full space-x-3 md:w-auto">
            <button id="filterDropdownButton" class="flex items-center justify-center w-full px-4 py-2 text-lg font-medium text-light-grey bg-dark-blue border rounded-lg md:w-auto focus:outline-none"
              type="button" (click)="this.showPopupView(!showPopUp,{'action':'addContent'})">
              ✨{{ 'Nuevo Curso'|translate }}✨
            </button>

            <a href="profile/{{userId}}">
              <button id="filterDropdownButton" data-dropdown-toggle="filterDropdown"
                class="flex items-center justify-center w-full px-4 py-2 text-lg font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none focus:z-10 focus:ring-4 focus:ring-gray-200"
                type="button">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4 mr-2 text-gray-400"
                  viewbox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                    clip-rule="evenodd" />
                </svg>
                {{ 'Perfil'|translate }}
                <svg class="-mr-1 ml-1.5 w-5 h-5" fill="currentColor" viewbox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path clip-rule="evenodd" fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div *ngIf="showMisTutores" class="p-4" style="height: 90vh; max-width: 95vw; overflow-y: auto;">
    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
      <ng-container *ngFor="let content of contents">
        <app-content-card
        [isInvitation]=false
          [content]="content"
          (editContent)="this.showPopupView(!showPopUp,{'action':'editContent','contentId':content._id})"
          (shareContent)="shareContent(content, $event)"
          >
        </app-content-card>
      </ng-container>

    </div>
  </div>


  <div *ngIf="showTutoresCompartidos" class="p-4" style="height: 90vh; max-width: 95vw; overflow-y: auto;">
    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
      <ng-container *ngFor="let invitedContent of invitedContents">
        <app-content-card
          [isInvitation]=true
          [content]="invitedContent"
          (click)="goToClassroom(invitedContent)"
          (shareContent)="shareContent(invitedContent, $event)">
        </app-content-card>
      </ng-container>
  </div>
</div>


<app-pop-up-view *ngIf="showPopUp" (closePopup)="this.showPopupView(!showPopUp)"></app-pop-up-view>
<app-shared-content *ngIf="showModal" (closeModalEvent)="closeModal()" [content_id]="selectedContent_id"></app-shared-content>
