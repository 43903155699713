import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { marked } from 'marked';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-create-content-resources-view',
  templateUrl: './create-content-resources-view.component.html',
  styleUrls: ['./create-content-resources-view.component.css']
})
export class CreateContentResourcesViewComponent {
  themes: any[] = [];
  @Output() closePopup = new EventEmitter<any>();
  contentId: any;
  language: any;
  script:string = '';
  formReg!: FormGroup;
  selectedTheme: any | undefined;

  constructor(private contentService: ContentService,
    private formBuilder: FormBuilder,private activatedRoute: ActivatedRoute,private router: Router) {}


  ngOnInit(): void {
    console.log("INIT CREATE CONTENT RESOURCES VIEW");
    console.log(this.contentId);
    console.log(this.language);



    this.formReg = this.formBuilder.group({
      slides: ['', Validators.required],
      speech_len: ['', Validators.required],
    });

    this.contentService.GetThemes().subscribe(params => {
        this.themes = params;
      });

    this.activatedRoute.queryParams.subscribe(params => {
      console.log("I'm in the popup:", params);
      this.contentId=params['contentId'];
      this.language=params['language'];
  });

  this.contentService.GetContentResources(this.contentId).subscribe(params => {
    this.script = params['methodScript'];
  });

  }

  close() {this.closePopup.emit();}

  selectTheme(theme: any): void {
    this.selectedTheme = theme;
    console.log(this.selectedTheme['_id']);
  }


  validateForm(): boolean {
    const form = this.formReg;
    for (const i in form.controls) {
      if (form.controls.hasOwnProperty(i)) {
        form.controls[i].markAsTouched();
        form.controls[i].updateValueAndValidity();
      }
    }
    return form.valid;
  }


  saveContentResources(){
    console.log(this.formReg?.value);
    console.log("SAVING");
    if (this.selectTheme != undefined){
      this.contentService.saveContentResources(this.contentId!,this.script,this.language,
        this.formReg?.value['speech_len'],this.selectedTheme['_id']).subscribe((data: any) => {
        console.log(data);
        this.close();

        // let queryParams = { action: 'editPresentation', contentId: this.contentId};
        // this.router.navigate([], {
        //   relativeTo: this.activatedRoute,
        //   queryParams: queryParams,
        //   replaceUrl: true
        // });
      });
    }
  }
}
