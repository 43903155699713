import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeViewComponent } from './home-view/home-view.component';
import { ProfileComponent } from './profile/profile.component';
import { BankImagesComponent } from '../Content/bank-images/bank-images.component';

const routes: Routes = [
  { path: 'home/:user_id',title:'home', component: HomeViewComponent },
  // { path: 'home/:user_id/popup/addContent',title:'home', component: HomeViewComponent },
  { path: 'bank-images', component: BankImagesComponent },
  {path: 'profile/:user_id',title:'profile', component: ProfileComponent}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class UserRoutingModule { }
