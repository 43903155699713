export class Content {
  _id: string;
  companyId: string;
  title: string;
  image: string;
  description: string;
  creationDate: string;
  vectorFilesNames: { source: string; filesNames: string[] }[];
  learnPlan: string;
  methodScript: string;
  presentationCode: string;
  presentationSpeechUrl: string;
  invitation_list: string[];
  resourcesStatus: string | null;

  constructor(
    _id: string = '',
    companyId: string = '',
    title: string = '',
    image: string = '',
    description: string = '',
    creationDate: string = '',
    vectorFilesNames: { source: string; filesNames: string[] }[] = [],
    learnPlan: string = '',
    methodScript: string = '',
    presentationCode: string = '',
    presentationSpeechUrl: string = '',
    invitation_list: string[] = [],
    resourcesStatus: string | null = null
  ) {
    this._id = _id;
    this.companyId = companyId;
    this.title = title;
    this.image = image;
    this.description = description;
    this.creationDate = creationDate;
    this.vectorFilesNames = vectorFilesNames;
    this.learnPlan = learnPlan;
    this.methodScript = methodScript;
    this.presentationCode = presentationCode;
    this.presentationSpeechUrl = presentationSpeechUrl;
    this.invitation_list = invitation_list;
    this.resourcesStatus = resourcesStatus;  // Initialize the new property
  }

  fromJson(json: any): Content {
    return new Content(
      json._id,
      json.companyId,
      json.title,
      json.image,
      json.description,
      json.creationDate,
      json.vectorFilesNames,
      json.learnPlan,
      json.methodScript,
      json.presentationCode,
      json.presentationSpeechUrl,
      json.invitation_list,
      json.resourcesStatus ?? null  // Handle null or missing resourcesStatus
    );
  }

  toJson(): Object {
    return {
      _id: this._id,
      companyId: this.companyId,
      title: this.title,
      image: this.image,
      description: this.description,
      creationDate: this.creationDate,
      vectorFilesNames: this.vectorFilesNames,
      learnPlan: this.learnPlan,
      methodScript: this.methodScript,
      presentationCode: this.presentationCode,
      presentationSpeechUrl: this.presentationSpeechUrl,
      invitation_list: this.invitation_list,
      resourcesStatus: this.resourcesStatus
    };
  }

}


