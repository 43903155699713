import { Component } from '@angular/core';

@Component({
  selector: 'app-bank-images',
  templateUrl: './bank-images.component.html',
  styleUrls: ['./bank-images.component.css']
})
export class BankImagesComponent {

}
