import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.css']
})
export class LoginViewComponent implements OnInit {

  formLogin: FormGroup;
  constructor(private userService: UserService,protected router: Router) {
    this.formLogin=new FormGroup({
      email: new FormControl(),
      password: new FormControl()
    })
   }

  ngOnInit(): void {}

  onSubmit(){
    this.userService.login(this.formLogin.value)
    .then(response=>{
      console.log(response);
      this.router.navigate(['/home', response]);
    })
    .catch( error=>{ console.log(error)});
  }

  async signUpWithGoogle(): Promise<void> {
    try{
      const result = await this.userService.singInWithGoogleProvider();
      console.log(result);
    }catch(error){
      console.log(error);
    }
  }
}
