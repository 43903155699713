import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-develop-banner-view',
  templateUrl: './develop-banner-view.component.html',
  styleUrls: ['./develop-banner-view.component.css']
})
export class DevelopBannerViewComponent implements OnInit {
  isProduction = environment.production;
  constructor() { }

  ngOnInit(): void {
  }

}
