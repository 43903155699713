import { UserService } from 'src/app/services/user.service';
import { Component, OnInit ,AfterViewChecked, ElementRef, ViewChild, Renderer2, ChangeDetectorRef, NgZone, Input} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, filter, switchMap, tap } from 'rxjs';
import { Content } from 'src/app/model/content';
import { ContentService } from 'src/app/services/content.service';
import { marked } from 'marked';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import Reveal from 'reveal.js';
import {Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { index } from 'd3';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-class-view',
  templateUrl: './class-view.component.html',
  styleUrls: ['./class-view.component.css'],
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ClassViewComponent implements OnInit {

  @ViewChild('autoScroll') private myScrollContainer: ElementRef | undefined;

  @ViewChild('messageInput') messageInput: ElementRef | undefined;
  @ViewChild('presentationContainer') presentationContainer!: ElementRef;

  @ViewChild('elementRef') public elementRef: ElementRef | undefined;

  currentSubtitleIndex: number = 0;
  lang: string = 'auto';
  showSubtitles: boolean = false;
  expand: boolean = false;
  language: string = 'english';
  presentationHTML: SafeHtml | undefined;
  planHTML: SafeHtml | undefined;

  audioPlayer: HTMLAudioElement | undefined;
  audioProgress: HTMLInputElement | undefined;
  showAudio=false;
  loadingPresentation: boolean = true;
  showModal:boolean = false;
  selectedContent: any;

  currentContent:Content | undefined;
  newMessage: string = '';
  tutorResponse: string ="";
  questionFeedback: string = '';
  activeTab: string = 'description';

  selectedOption = 'ES';
  dropdownOpen = false;
  showPopUp = false;

  thinking: boolean = false;
  waiting_questions: boolean = false;

  isPlaying: boolean = false;

  user_id: string| undefined;
  content_id: string | undefined;
  tutor_avatar_url : string | undefined;
  userScrolled: boolean = true;
  useMemory: any;

  currentTrackIndex: number = 0;


  mp3Urls:string[] | undefined;
  speechScripts: string[] = [];
  presentation_v_index: number = 0;
  presentation_h_index: number = 0;

  private initialized = false;

  questions: string[] = [];

  assessment_questions: string[] = [];

  messages: any[] = [];
  wordLimit: number = 10; // Límite de palabras a mostrar en cada fragmento de subtitulo
  visibleWords: string[] = [];
  currentWordIndex: number = 0;

  usedQuestionIndices: Set<number> = new Set();

  messages_index: number = 0;

  getWords(subtitle: string): string[] {
    return subtitle.split(' ');
  }

  constructor(private renderer: Renderer2, private route: ActivatedRoute,private translateService: TranslateService,
     private contentService: ContentService,private userService:UserService,
     private sanitizer: DomSanitizer,private location: Location) {
      this.translateService.addLangs(['en', 'es', 'fr']);
      this.translateService.setDefaultLang('auto');
      const browserLang = this.translateService.getBrowserLang() || 'es';
      this.translateService.use(browserLang.match(/en|es|fr/) ? browserLang : 'es');
     }


  ngOnInit(): void {
    this.syncSubtitles();
    const browserLang = window.navigator.language.split('-')[0];
    this.translateService.setDefaultLang(browserLang);
    this.translateService.use(browserLang);
    if (this.lang !== 'auto') {
      this.lang = browserLang;
    }
    setInterval(() => {
      this.showNextWords(); //Revisando el intervalo que genera la latencia
    }, 4000);
   // this.getContentResources();
    console.log("INIT CLASS");
    this.route.paramMap.subscribe(params => {
      console.log(params);
      this.content_id = params.get("content_id")!;
      this.useMemory=params.get("memory")!;
      this.user_id = params.get("user_id")!;
      this.tutor_avatar_url = 'https://boostfs.s3.amazonaws.com/temp/6a1b1082-1846-4b18-8b6b-c7b76d422824.png';
      this.getContentResources();
      // this.initializeReveal();
    });
  }


  ChangeLang(event: any) {
    const selectedLanguage = event.target.value;
    if (selectedLanguage === 'auto') {
      this.translateService.use(window.navigator.language.split('-')[0]);
    } else {
      this.translateService.use(selectedLanguage);
    }
    localStorage.setItem('lang', selectedLanguage);
  }

  getContentResources(){
    console.log("GET CONTENT RESOURCES");
    this.contentService.GetContentResources(this.content_id!).subscribe((data) => {
      console.log(data);
      this.questions= data['questions'];
      this.assessment_questions= data['option_questions'];
      this.language = data['language'];
      if(this.assessment_questions.length==0){this.assessment_questions= data['assessment_questions'];}
      console.log(this.assessment_questions);
      this.getPresentation(data['presentationCode']);
      this.getSpeech(data['presentationSpeechUrl']);
      this.speechScripts = data['speech_scripts'];
    });
  }


  initializeReveal() {
  this.loadingPresentation = false;
  this.showAudio = true;
  setTimeout(() => {
    Reveal.initialize({
      embedded: true,
      controls: true,
      slideNumber: 'c/t',
      margin: 0,
    });
    Reveal.addEventListener('slidechanged', this.onSlideChanged.bind(this));
  }, 0);
}



  shareContent() {
    console.log("SHARE CONTENT");
    this.toggleModal();
  }


  ngAfterViewChecked() {
    if (this.thinking){
      this.scrollToBottom();
    }
  }


  closeModal() {
    this.showModal = false;
  }

  toggleModal(){
  this.showModal = !this.showModal;
  }


toggleDropdown() {
  this.dropdownOpen = !this.dropdownOpen;
}

selectOption(option: string) {
  this.selectedOption = option;
  this.dropdownOpen = false;
}


  /**********************************************************PRESENTATION-AUDIO*******************************************************************************/




  onSlideChanged(event: any) {
    console.log('Slide changed to index: ', event.indexh, ' ', event.indexv);
    if (event.indexh > this.presentation_h_index || event.indexv > this.presentation_v_index) {
      this.nextTrack();
      this.nextSubtitle();
    } else if (event.indexh < this.presentation_h_index || event.indexv < this.presentation_v_index) {
      this.previousTrack();
      this.previousSubtitle();
    }
    this.presentation_h_index = event.indexh;
    this.presentation_v_index = event.indexv;


  }

  toggleSubtitles(): void {
    this.showSubtitles = !this.showSubtitles;
  }


  nextSubtitle(): void {
    if (this.currentSubtitleIndex < this.speechScripts.length - 1) {
      this.currentSubtitleIndex++;
    }
  }

  previousSubtitle(): void {
    if (this.currentSubtitleIndex > 0) {
      this.currentSubtitleIndex--;
    }
  }
  playAudio() {
    const audioPlayer = document.getElementById('audioPlayer') as HTMLAudioElement;
    audioPlayer.play();
    this.isPlaying = true;
  }

  pauseAudio() {
    const audioPlayer = document.getElementById('audioPlayer') as HTMLAudioElement;
    audioPlayer.pause();
    this.isPlaying = false;
  }

  toggleAudio() {
    if (this.isPlaying) {
      this.pauseAudio();
    } else {
      this.playAudio();
    }
  }

  changeTab(tab: string) {
    this.activeTab = tab;
  }


getPresentation(html:string) {
  this.presentationHTML = this.sanitizer.bypassSecurityTrustHtml(html);
  if (!localStorage.getItem('reloaded')) {
    localStorage.setItem('reloaded', 'true');
    window.location.reload();
  } else {
    this.loadingPresentation = false;
    this.showAudio = true;
    localStorage.removeItem('reloaded');
  }
  this.initializeReveal();
}


getSpeech(presentationSpeechUrls:any){
  setTimeout(() => {
    this.audioPlayer = <HTMLAudioElement>document.getElementById('audioPlayer');
    console.log(presentationSpeechUrls);
    console.log(typeof presentationSpeechUrls);
    if (typeof presentationSpeechUrls === 'object') {
      this.audioPlayer.setAttribute("src", presentationSpeechUrls[0]);
      this.mp3Urls = presentationSpeechUrls;
    }else{
      this.audioPlayer.setAttribute("src", presentationSpeechUrls);
    }
    this.audioProgress = <HTMLInputElement>document.getElementById('audioProgress');
    this.audioPlayer.ontimeupdate = () => { this.updateAudioProgress() };
}, 0);
}

  nextTrack(): void {
    if (this.mp3Urls!= undefined && this.audioPlayer && this.currentTrackIndex < this.mp3Urls.length - 1) {
      this.currentTrackIndex++;
      this.audioPlayer.src = this.mp3Urls[this.currentTrackIndex];
      if (this.isPlaying) {
        this.playAudio();
      }
    }
  }

  previousTrack(): void {
    if (this.mp3Urls!= undefined && this.audioPlayer && this.currentTrackIndex > 0) {
      this.currentTrackIndex--;
      this.audioPlayer.src = this.mp3Urls[this.currentTrackIndex];

      if (this.isPlaying) {
        this.playAudio();

      }

    }
  }




  changeAudioPosition(event:any) {
    this.audioPlayer!.currentTime = this.audioPlayer!.duration * (event.target.value / 100);
  }

  updateAudioProgress() {
    this.audioProgress!.value = String((this.audioPlayer!.currentTime / this.audioPlayer!.duration) * 100);

  }



  showNextWords(): void {
    const currentSubtitle = this.speechScripts[this.currentSubtitleIndex];
    if (currentSubtitle) {
      const words = this.getWords(currentSubtitle);
      const remainingWords = words.slice(this.currentWordIndex);
      const endIndex = Math.min(this.wordLimit, remainingWords.length);
      this.visibleWords = remainingWords.slice(0, endIndex);
      this.currentWordIndex += endIndex;

      if (this.currentWordIndex >= words.length) {

        this.currentWordIndex = 0;
        this.visibleWords = [];
      }
    } else {
      this.visibleWords = [];
    }
  }

   syncSubtitles(): void {
    const audioPlayer = this.audioPlayer;
    const subtitles = this.speechScripts;

    if (!audioPlayer) {
      console.error('Audio player is not initialized.');
      return;
    }

    audioPlayer.addEventListener('timeupdate', () => {
      const currentTime = audioPlayer.currentTime;
    });
  }


  /********************************************************************************************************************************************/






  /**********************************************************CHAT*******************************************************************************/



  scrollToBottom(): void {
    try {
      this.myScrollContainer!.nativeElement.scrollTop = this.myScrollContainer!.nativeElement.scrollHeight;
    } catch(err) { }
  }



  async sendMessage(message:string) {
    var message_data={"type":"user","content":message};
    this.messages_index++;
    this.messages.push(message_data);
    this.newMessage = '';
    this.messages.push({"type":"tutor","content":""});
    this.messages_index++;
    this.thinking = true;
    let to_use_user_id='';
    if (this.useMemory==='true'){to_use_user_id=this.user_id!}
    else{to_use_user_id='0';}

    this.contentService.askTutorChat(message,to_use_user_id,this.content_id!,this.language).subscribe({
      next: (data) => {
        if (data=="[¬TUTOR_END¬]"){
          this.tutorResponse="";
          if(this.messages_index==4){
            let randomIndex;
            do {
              randomIndex = Math.floor(Math.random() * this.assessment_questions.length);
            } while (this.usedQuestionIndices.has(randomIndex) && this.usedQuestionIndices.size < this.assessment_questions.length);
            if (this.usedQuestionIndices.size < this.assessment_questions.length) {
              this.messages.push({ "type": "question", "content": this.assessment_questions[randomIndex] });
              this.usedQuestionIndices.add(randomIndex);
              this.waiting_questions = true;
            } else {
              console.log("All questions have been used.");
              this.waiting_questions = false;
            }
            this.messages_index=0;
          }else {
            console.log("All questions have been used.");

            this.contentService.updateShortMemory(this.content_id!,this.user_id,this.messages[this.messages.length - 2]['content'],this.messages[this.messages.length - 1]['content'],undefined).subscribe({
              next: (data) => {
                console.log(data);
              },
              error: error => console.error(error)
            });

          }

          setTimeout(() => {
            this.thinking = false;
            if (this.messageInput && this.messageInput.nativeElement) {
              console.log("FOCUS");
              this.renderer.selectRootElement(this.messageInput.nativeElement).focus();
            }
          },1);
        }else{
          this.tutorResponse=this.tutorResponse+data;
          const message_data={"type":"tutor","content":this.tutorResponse};
          this.messages[this.messages.length - 1] = message_data;
        }
      },
      error: error => console.error(error)
    });
  }


  getAnswerFeedback(question: any): Promise<void> {
    this.thinking = true;
    return new Promise((resolve, reject) => {
      this.contentService.getQuestionFeedback(question.content, this.content_id!, this.language).subscribe({
        next: (data) => {
          if (data == "[¬TUTOR_END¬]") {
            this.questionFeedback = "";
            this.contentService.updateShortMemory(
              this.content_id!,
              this.user_id,
              this.messages[this.messages.length - 3]['content'],
              this.messages[this.messages.length - 2]['content'],
              question.content
            );
            setTimeout(() => {
              this.thinking = false;
              if (this.messageInput && this.messageInput.nativeElement) {
                console.log("FOCUS");
                this.renderer.selectRootElement(this.messageInput.nativeElement).focus();
              }
              resolve();
            }, 1);
          } else {
            this.questionFeedback = this.questionFeedback + data;
            question.content.explanation = this.questionFeedback;
            resolve();
          }
        },
        error: (error) => {
          console.error(error);
          reject(error);
        }
      });
    });
  }

  getStudentQuestionBackgroundFeedback(question: any){
      this.contentService.getUserQuestionBackgroundFeedback(question.content, this.content_id!, this.language).subscribe({
        next: (data: any) => {
          console.log(data);
          question.content.background_feedback = data;
          this.contentService.updateShortMemory(
            this.content_id!,
            this.user_id,
            this.messages[this.messages.length - 3]['content'],
            this.messages[this.messages.length - 2]['content'],
            question.content
          ).subscribe({ next: (data) => {
            console.log("Done updating short memory.");
          }});
        },
        error: (error) => {
          console.error(error);
        }
      });
  }


  validateAnswer(selectedOption: string, correctAnswer: string, questionIndex: number){
    const message = this.messages[questionIndex];
    if (message.content.selectedOption == null) {
      this.messages[questionIndex]['content']['selectedOption'] = selectedOption;
      message.content.isCorrect = selectedOption === correctAnswer;
      if (message.content.isCorrect) {
        console.log('Correct answer!');
      } else {
        console.log('Incorrect answer. Try again!');
      }

      try {
        this.getStudentQuestionBackgroundFeedback(message);
        this.getAnswerFeedback(message);
        console.log("Messages");
        console.log(this.messages);
        this.waiting_questions = false;
      } catch (error) {
        console.error(error);
        console.log("Messages");
        console.log(this.messages);
        this.waiting_questions = false;
      }
    }
  }


  getOptionClass(message: any, option: string): string {
    if (message.content.selectedOption==null) {
      return "cursor-pointer p-2 my-1 transition-colors duration-300 bg-blue-500 text-white hover:text-blue-500 hover:bg-white";
    } else {
      if (message.content.selectedOption === option) {
        return message.content.isCorrect ? "bg-green-300 text-white" : "bg-red-500 text-white";
      } else {
        return "bg-blue-500 text-white";
      }
    }
  }

  convertToHtml(markdown: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(marked(markdown) as string);
  }


  /********************************************************************************************************************************************/


}
