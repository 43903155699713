<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta http-equiv="X-UA-Compatible" content="ie=edge" />
  <meta name="description" content="Simple landing page" />
  <meta name="keywords" content="" />
  <meta name="author" content="" />
  <link rel="stylesheet" href="https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css"/>
  <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700" rel="stylesheet" />
  <style>
    .gradient {
      background: linear-gradient(90deg, #a4c3e0 12%, #6fa1d2 50%, #3b80c7 100%);
    }
  </style>
</head>
<body class="gradient h-screen">

  <div class="flex flex-col bg-beige h-screen">

    <div class="flex items-center justify-between p-4 md:p-8" style="height: 7vh;">
      <h1 class="text-3xl font-black text-dark-blue" style="font-family: Fredoka, sans-serif;">Learnable</h1>
      <div class="flex justify-end space-x-4">
        <a href="/auth/register" id="registerButton" class="flex items-center justify-center px-4 py-2 text-lg font-medium text-black bg-[#EAE0D5] rounded-lg focus:outline-none" style="min-width: 60px;">{{ 'Sign Up' | translate }}</a>
        <a href="/auth/login" id="loginButton" class="flex items-center justify-center px-4 py-2 text-lg font-medium text-light-grey bg-dark-blue rounded-lg focus:outline-none" style="min-width: 60px;">{{ 'Login' | translate }}</a>
      </div>
    </div>

    <div class="flex-grow flex flex-col md:flex-row items-center justify-center">
      <div class="bg-blue w-11/12 md:w-1/2 h-auto md:h-[50vh] lg:h-[60vh] xl:h-[70vh] mt-2 flex flex-col items-center">
        <div class="inner_html">
          <h1 class="text-4xl md:text-6xl lg:text-8xl font-black text-[#c59c6f] mt-5 md:mt-10 ml-5 md:ml-10" style="font-family: Fredoka, sans-serif;">Powering <br> Education <br> with AI</h1>
        </div>
      </div>
      <div class="bg-blue w-11/12 md:w-1/2 h-auto md:h-[50vh] lg:h-[60vh] xl:h-[70vh] mt-12 md:ml-10">
        <div class="inner_html">
          <img class="w-full h-auto md:h-[50vh]" src="./assets/images/learn.png" alt="">
        </div>
      </div>
    </div>
    
  </div>
</body>
</html>
