<div class="flex flex-col bg-light-grey p-1" style="height: 98vh;">
  <div class="flex items-center justify-between p-4" style="height: 7vh;">
    <h1
      class="text-4xl font-black text-dark-blue"
      style="font-family: Fredoka, sans-serif;"
    >
      Learnable
    </h1>
    <div class="flex items-center space-x-5">
      <div class="relative" (click)="toggleDropdown()">
        <ul>
          <li class="mb-2">
            <select
              class="form-select"
              (change)="ChangeLang($event)"
              id="languageSelect"
            >
              <option [value]="'es'" [selected]="lang === 'es'"
                >🌎{{ 'ES' | translate }}</option
              >
              <option [value]="'en'" [selected]="lang === 'en'"
                >🌎{{ 'EN' | translate }}</option
              >
            </select>
          </li>
        </ul>
      </div>
      <button
        (click)="toggleModal()"
        id="filterDropdownButton"
        class="flex items-center justify-center px-4 py-2 text-lg font-medium text-light-grey bg-dark-blue rounded-lg focus:outline-none"
        style="min-width: 120px;"
      >
        ✨{{ 'Compartir' | translate }}✨
      </button>
    </div>
  </div>
  <app-shared-content
    *ngIf="showModal"
    (closeModalEvent)="closeModal()"
    [content_id]="content_id"
  ></app-shared-content>

  <div class="flex-grow flex flex-col md:flex-row">
    <div class="w-full md:w-[60%] h-full bg-beige rounded-lg mb-2 md:mr-2">
      <div class="container" #presentationcontainer>
        <div class="inner_html" [innerHTML]="presentationHTML"></div>
      </div>

      <div
        *ngIf="showSubtitles && speechScripts && speechScripts.length > 0"
        class="text-lg font-bold bg-gray-800 bg-opacity-75 text-white p-3 rounded-lg shadow-md"
      >
        <ng-container
          *ngFor="let word of visibleWords; let i = index; let last = last;"
        >
          <span [style.display]="'inline-block'" [@fadeInAnimation]
            >{{ word }}</span
          >
          <span *ngIf="!last">&nbsp;</span>
        </ng-container>
      </div>
      <div *ngIf="showAudio" class="flex items-center w-[95%] m-2">
        <div class="flex space-x-1">
          <button
            (click)="toggleAudio()"
            class="bg-dark-blue border-none text-white text-center no-underline inline-block text-base rounded-full cursor-pointer flex items-center justify-center"
            style="height: 40px; width: 40px;"
          >
            <i *ngIf="isPlaying; else pauseIcon" class="fa fa-pause"></i>
            <ng-template #pauseIcon>
              <i class="fa fa-play"></i>
            </ng-template>
          </button>
        </div>
        <button
          (click)="toggleSubtitles()"
          class="bg-dark-blue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 m-2"
        >
          ㏄
        </button>
        <div class="p-[10px] w-[100%] h-[40px] flex items-center">
          <input
            id="audioProgress"
            type="range"
            min="0"
            max="100"
            value="0"
            (change)="changeAudioPosition($event)"
            class="w-full"
          />
          <audio id="audioPlayer"></audio>
        </div>
      </div>
    </div>
    <div class="w-full md:w-[45%] h-full bg-beige rounded-lg flex flex-col">
      <div class="w-full h-full flex-auto flex flex-col">

        <div class="h-[10vh] flex items-center justify-center">
          <div
            class="bg-cover bg-center bg-no-repeat rounded-full"
            [style.background-image]="'url(' + tutor_avatar_url + ')'"
            style="width: 8vh; height: 8vh; background-size: cover;"
          ></div>
        </div>
        <div
          class="h-[60vh] flex flex-col items-end overflow-y-auto rounded-lg"
          style="
            background-color: #e2d6ca;
            margin-left: 1vw;
            margin-right: 1vw;
          "
          #autoScroll
        >
          <div
            *ngFor="let message of messages; index as i"
            [class]="message.type === 'question' ? 'self-start bg-blue-500 rounded-lg text-white text-lg p-3 mx-2.5 my-2 whitespace-pre-wrap w-auto' : (message.type === 'user' ? 'self-end bg-dark-blue rounded-tl-lg rounded-tr-lg rounded-bl-lg text-white text-lg p-1.5 mx-2.5 my-2 whitespace-pre-wrap w-auto' : 'self-start bg-custom-brown rounded-tl-lg rounded-tr-lg rounded-br-lg text-white text-lg p-1.5 mx-2.5 my-2 whitespace-pre-wrap w-auto')"
            style="margin: 12px; padding-left: 5px; padding-right: 5px;"
          >
            <ng-container
              *ngIf="message.type !== 'question'; else questionDisplay"
            >
              <div [innerHTML]="convertToHtml(message.content)"></div>
            </ng-container>
            <ng-template #questionDisplay>
              <div>
                <strong>{{ message.content.question }}</strong>
                <ul>
                  <li
                    *ngFor="let option of message.content.options"
                    (click)="validateAnswer(option, message.content.correct_answer, i)"
                    [class]="getOptionClass(message, option)"
                    class="cursor-pointer p-2 my-1 transition-colors duration-300 bg-blue-500 text-white hover:text-blue-500 hover:bg-white"
                  >
                    {{ option }}
                  </li>
                </ul>
                <div
                  *ngIf="message.content.explanation"
                  class="bg-white rounded border border-solid text-blue-500 p-4 m-2"
                >
                  {{ message.content.explanation }}
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div
          class="scroll-container h-[10vh] flex flex-col overflow-y-auto space-y-2 p-2 mt-3 mb-3"
        >
          <div
            *ngFor="let question of questions"
            class="question-item flex items-center min-w-max whitespace-nowrap text-light-grey bg-custom-brown hover:bg-white hover:text-custom-brown px-6 rounded-lg shadow-md cursor-pointer"
            (click)="sendMessage(question)"
            style="font-size: 1.7vh;"
          >
            {{ question }}
          </div>
        </div>
        <div
          *ngIf="!thinking && !waiting_questions; else loading"
          class="h-[10vh] flex items-start justify-center gap-2 px-4 mb-16"
          style="padding-left: 5px; padding-right: 5px;"
        >
          <textarea
            #messageinput
            class="flex-1 p-5 resize-none rounded-lg border border-gray-300"
            placeholder="{{ 'Escribe algo...' | translate }}"
            [(ngModel)]="newMessage"
            (keyup.enter)="sendMessage(newMessage)"
            style="height: 60px; font-size: 18px; padding: 5px;"
          ></textarea>
          <button
            class="inline-flex items-center py-4 px-6 rounded-l-xl rounded-t-xl bg-custom-brown hover:bg-white hover:text-custom-brown focus:bg-gray-200 text-light-grey font-bold leading-loose transition duration-200"
            (click)="sendMessage(this.newMessage)"
            style="padding: 10px 40px; font-size: 20px;"
          >
            <i class="fas fa-brain text-xl mr-2"></i>
          </button>
        </div>
        <ng-template #loading>
          <div class="flex justify-center items-center space-x-2">
            <div
              class="w-3.5 h-3.5 bg-current rounded-full animate-bounce"
            ></div>
            <div
              class="w-3.5 h-3.5 bg-current rounded-full animate-bounce200"
            ></div>
            <div
              class="w-3.5 h-3.5 bg-current rounded-full animate-bounce400"
            ></div>
          </div>
        </ng-template>
      </div>
    </div>



  </div>
</div>
