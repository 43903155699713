import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import Reveal from 'reveal.js';
import { Subscription } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-edit-presentation-view',
  templateUrl: './edit-presentation-view.component.html',
  styleUrls: ['./edit-presentation-view.component.css']
})
export class EditPresentationViewComponent {
  @Output() closePopup = new EventEmitter<any>();
  presentationHTML: SafeHtml | undefined;
  loadingPresentation: boolean = true;
  contentId: any;
  formReg: FormGroup | undefined;
  presentations_slides:any;
  presentations_slides_cp:any;
  presentation_v_index: number = 0;
  presentation_h_index: number = 0;
  index=0;
  formChangesSubscription: Subscription | undefined;
  presentation_code: string = "";
  loading: boolean = false;
  constructor(private activatedRoute: ActivatedRoute,private sanitizer: DomSanitizer,
    private contentService: ContentService, private formBuilder: FormBuilder) { }


  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log("I'm in the popup:", params);
      this.contentId=params['contentId'];
  });
    this.formReg = this.formBuilder.group({
      title: ["", Validators.required],
      subtitle: ["", Validators.required],
      content: ["", Validators.required],
      image: ["", Validators.required],
    });
    this.getContentResources();
  }

  initSlidesData(){
    this.formChangesSubscription = this.formReg!.valueChanges.subscribe(values => {
      this.updateCurrentSlideData(values);
    });
    this.formReg!.patchValue({
      title: this.presentations_slides['slides_content'][this.index].title,
      subtitle: this.presentations_slides['slides_content'][this.index].subtitle,
      content: this.presentations_slides['slides_content'][this.index].content,
      image: this.presentations_slides['slides_content'][this.index].image
    });
  }


  getContentResources(){
    console.log("Content id:",this.contentId)
    this.contentService.GetContentResources(this.contentId!).subscribe((data) => {
      console.log("🔔Cotent data");
      console.log(data);
      console.log(data['presentationContentParts']);
      this.presentations_slides=data['presentationContentParts'];
      this.presentations_slides_cp=JSON.parse(JSON.stringify(data['presentationContentParts']));
      this.presentation_code=data['presentationCode'];
      setTimeout(() => {
        this.initSlidesData();
      }, 0);
      this.getPresentation(this.presentation_code);
    });
  }



  initializeReveal() {
    setTimeout(() => {
      Reveal.initialize({
        embedded: true,
        controls: true,
        slideNumber: 'c/t',
        margin: 0,
      });
      Reveal.addEventListener('slidechanged', this.onSlideChanged.bind(this));
      Reveal.addEventListener('ready', function(event) {
        var slideNumber = document.querySelector('.reveal .slide-number');
        if (slideNumber) {
          slideNumber.addEventListener('click', function(e) {
            e.preventDefault();
          });
        }
      });
    }, 0);
  }

  close() {this.closePopup.emit();}

  getPresentation(html:string) {
    this.presentationHTML = this.sanitizer.bypassSecurityTrustHtml(html);
    if (!localStorage.getItem('reloaded')) {
      localStorage.setItem('reloaded', 'true');
      window.location.reload();
    } else {
      this.loadingPresentation = false;
      localStorage.removeItem('reloaded');
    }
    this.initializeReveal();
  }


  onSlideChanged(event:any) {
    console.log('Slide changed to index: ', event.indexh, ' ', event.indexv);
    if (event.indexh>this.presentation_h_index || event.indexv>this.presentation_v_index) {
      this.index++;
    }else if (event.indexh<this.presentation_h_index || event.indexv<this.presentation_v_index) {
      this.index--;
    }
    this.formReg!.patchValue({
      title: this.presentations_slides['slides_content'][this.index].title,
      subtitle: this.presentations_slides['slides_content'][this.index].subtitle,
      content: this.presentations_slides['slides_content'][this.index].content,
      image: this.presentations_slides['slides_content'][this.index].image
    });
    this.presentation_h_index = event.indexh;
    this.presentation_v_index = event.indexv;
  }

  updateCurrentSlideData(values: any) {
    this.presentations_slides['slides_content'][this.index] = { ...values };
  }


  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      console.log(file.name);
      this.presentations_slides['slides_content'][this.index]=file;
      this.formReg!.patchValue({
        image: file
      });
    }
  }

  updateContentResources(){
    console.log("Update resources");
    console.log("Old!");
    console.log(this.presentations_slides_cp);
    console.log("New!");
    console.log(this.presentations_slides);
    this.loading=true;
    console.log(this.presentations_slides);
    this.contentService.uploadPresentationImageFiles(this.presentations_slides['slides_content']).then((new_presentation_slides: any) => {
      console.log("DONE UPLOADING IMAGES✅✅");
      this.presentations_slides['slides_content']=new_presentation_slides;
      console.log(new_presentation_slides);
      console.log("SAVING");
      this.contentService.updateContentResources(this.contentId!,this.presentation_code,this.presentations_slides_cp,this.presentations_slides).subscribe((data: any) => {
        console.log(data);
        if (data=="✅ OK"){
          console.log("DONE✅✅");
          this.loading = false;
          this.close();
        }
    });
    });
  }

}
