import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

export interface UserStudent {
  email: string;
  password: string;
  name: string;
  age: number;
  gender: string;
  description: string;
}

@Component({
  selector: 'app-register-view',
  templateUrl: './register-view.component.html',
  styleUrls: ['./register-view.component.css']
})
export class RegisterViewComponent implements OnInit {

  formReg: FormGroup | undefined;


  constructor(private formBuilder: FormBuilder, private userService:UserService, protected router: Router) {}

  ngOnInit(): void {
    this.formReg = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    });
  }


  onSubmit(): void {
    console.log(this.formReg?.value);
    this.userService.register(this.formReg!).subscribe({
      next: (response) => {
        console.log(response);
        console.log("🟢DONE");
        this.router.navigate(['/home', response['_id']]);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }
}
