import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { marked } from 'marked';
import { Content } from 'src/app/model/content';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-create-methodological-method-view',
  templateUrl: './create-methodological-method-view.component.html',
  styleUrls: ['./create-methodological-method-view.component.css']
})
export class CreateMethodologicalMethodViewComponent {
  themes: any[] = [];
  @Output() closePopup = new EventEmitter<any>();
  @ViewChild('messageInput') messageInput: ElementRef | undefined;
  @ViewChild('autoScroll')  myScrollContainer: ElementRef | undefined;
  contentId: any;
  language: any;
  thinking: boolean = false;
  script:string = '';
  loadingMethodScript: boolean = false;
  loadingMessage: string = "Loading...";
  formReg!: FormGroup;
  selectedTheme: any | undefined;
  current_content: Content | undefined;
  learning_goal_templates: any[]=[];

  english_learning_goal_templates = [
    { title: "Understand the Content", description: "Gain a comprehensive and in-depth comprehension of the subject matter, grasping all essential elements and underlying principles." },
    { title: "Apply Knowledge", description: "Effectively use acquired concepts and theories in real-world scenarios and practical applications to solve problems." },
    { title: "Develop Critical Thinking", description: "Enhance your capacity to critically analyze and evaluate information, arguments, and ideas from various sources to make informed decisions." },
    { title: "Enhance Communication Skills", description: "Improve your ability to clearly and effectively communicate ideas, thoughts, and facts in both verbal and written forms." },
    { title: "Foster Collaboration", description: "Promote and improve teamwork skills, enabling you to work effectively and efficiently in diverse group settings." },
    { title: "Cultivate Ethical Understanding", description: "Develop a strong sense of ethics and responsibility, encouraging you to act with integrity and consider the ethical implications of your decisions." },
    { title: "Improve Technological Proficiency", description: "Enhance your skills to effectively utilize modern technologies in your field of work, adapting to new tools and trends." },
    { title: "Assess Understanding", description: "Evaluate the depth and breadth of knowledge and understanding you have achieved, ensuring you can apply this understanding effectively in various contexts." }
];


  spanish_learning_goal_templates = [
    { title: "Entender el Contenido", description: "Obtener una comprensión completa y profunda del tema, abarcando todos los elementos esenciales y los principios subyacentes." },
    { title: "Aplicar el Conocimiento", description: "Utilizar de manera efectiva los conceptos y teorías aprendidos en escenarios del mundo real y aplicaciones prácticas para resolver problemas." },
    { title: "Desarrollar el Pensamiento Crítico", description: "Mejorar tu capacidad para analizar y evaluar críticamente información, argumentos e ideas provenientes de diversas fuentes, con el fin de tomar decisiones informadas." },
    { title: "Mejorar las Habilidades de Comunicación", description: "Incrementar tu habilidad para comunicar ideas, pensamientos y hechos de manera clara y efectiva, tanto verbalmente como por escrito." },
    { title: "Fomentar la Colaboración", description: "Promover y mejorar las habilidades de trabajo en equipo, permitiéndote trabajar de manera efectiva y eficiente en entornos grupales diversos." },
    { title: "Cultivar la Comprensión Ética", description: "Desarrollar un fuerte sentido de la ética y responsabilidad, fomentando actuar con integridad y considerar las implicaciones éticas de tus decisiones." },
    { title: "Mejorar la Proficiencia Tecnológica", description: "Mejorar tus habilidades para utilizar efectivamente las tecnologías modernas en tu campo de trabajo, adaptándote a nuevas herramientas y tendencias." },
    { title: "Evaluar el Entendimiento", description: "Evaluar la profundidad y amplitud del conocimiento y entendimiento alcanzado, asegurando que puedas aplicar este entendimiento efectivamente en diversos contextos." },
    { title: "Entrenamiento de Producto", description: "Entrenar a tu equipo de ventas en el producto XX para que adquieran un mayor conocimiento de los beneficios, características y manejo de objeciones, ayudando así a nuestros clientes a tomar mejores decisiones." }
  ];








  constructor(private renderer: Renderer2,
    private route: ActivatedRoute,private contentService: ContentService,
    private formBuilder: FormBuilder,private activatedRoute: ActivatedRoute,private router: Router) {}


  ngOnInit(): void {
    console.log("INIT CLASS");
    this.route.paramMap.subscribe(params => {
      this.renderer.selectRootElement(this.messageInput!.nativeElement).focus();
    });

    this.formReg = this.formBuilder.group({
      learning_goal: ['', Validators.required]
    });

    this.contentService.GetThemes().subscribe(
      response => {
        this.themes = response;
      });

    this.activatedRoute.queryParams.subscribe(params => {
      console.log("I'm in the popup:", params);
      this.contentId=params['contentId'];
      this.language=params['language'];
  });

    if(this,this.language=="spanish"){
      this.learning_goal_templates=this.spanish_learning_goal_templates;
    }else if(this.language=="english"){
      this.learning_goal_templates=this.english_learning_goal_templates;
    }

    this.contentService.GetContentResources(this.contentId!).subscribe((data: any) => {
      console.log(data);
      this.current_content = new Content().fromJson(data);
      console.log("OK CONTENT",this.current_content);
      console.log(this.current_content!.toJson());
    });




  }

  close() {this.closePopup.emit();}

  selectTheme(theme: any): void {
    this.selectedTheme = theme;
    console.log(this.selectedTheme['_id']);
  }


  validateForm(): boolean {
    const form = this.formReg;
    for (const i in form.controls) {
      if (form.controls.hasOwnProperty(i)) {
        form.controls[i].markAsTouched();
        form.controls[i].updateValueAndValidity();
      }
    }
    return form.valid;
  }

  createMethodScript(){
    console.log(this.formReg?.value);
    console.log(this.formReg?.value['learning_goal']);
    this.loadingMethodScript=true;
    this.contentService.getMethodScript(this.contentId!,this.formReg?.value['learning_goal'],this.language).subscribe((data: any) => {
      console.log(data);
      this.script=data;
      this.loadingMethodScript=false;
    });
  }


  updateContentResources(){
    console.log(this.formReg?.value);
    console.log("SAVING");
    this.current_content!.methodScript = this.script;
    this.contentService.updateContent(this.current_content!).subscribe((data: any) => {
      console.log(data);
      console.log("SAVED");

      let queryParams = { action: 'createContentResources', contentId: this.contentId, language: this.language};
      this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          replaceUrl: true
        });
    });
  }

  onSelect(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    console.log('Selected option:', selectElement.value);
    this.formReg.controls['learning_goal'].setValue(selectElement.value);
  }


  // onSelect(event: Event): void {
  //   const selectElement = event.target as HTMLSelectElement;
  //   const selectedGoal = selectElement.options[selectElement.selectedIndex].value as any;
  //   if (selectedGoal) {
  //     console.log('Selected option:', selectedGoal.title, selectedGoal.description);
  //     this.formReg.controls['learning_goal'].setValue(`${selectedGoal.title} - ${selectedGoal.description}`);
  //   }
  // }



  // saveContentResources(){
  //   console.log(this.formReg?.value);
  //   console.log("SAVING");
  //   this.loadingMethodScript=true;
  //   if (this.selectTheme != undefined){
  //     this.contentService.saveContentResources(this.contentId!,this.script,this.language,
  //       this.formReg?.value['slides'],this.formReg?.value['speech_len'],this.selectedTheme['_id']).subscribe((data: any) => {
  //       console.log(data);
  //       this.loadingMethodScript=false;
  //       this.close();

  //       // let queryParams = { action: 'editPresentation', contentId: this.contentId};
  //       // this.router.navigate([], {
  //       //   relativeTo: this.activatedRoute,
  //       //   queryParams: queryParams,
  //       //   replaceUrl: true
  //       // });
  //     });
  //   }
  // }

}
