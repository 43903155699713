import { Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Route } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-add-content-view',
  templateUrl: './add-content-view.component.html',
  styleUrls: ['./add-content-view.component.css']
})
export class AddContentViewComponent {
  files: File[] = [];
  fileSet: Set<string> = new Set();
  uploadedFilesUrls: string[] = [];
  @Output() closePopup = new EventEmitter<any>();
  selectElement = document.getElementById('language') as HTMLSelectElement;
  contentForm: FormGroup | undefined;
  loadingCreatingContent: boolean = false;
  showSharing:boolean=false;
  fileSize: number = 10485760;
  showMethodologicalContentPopup = false;
  contentId: any;

  constructor(private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,private router: Router, private contentService:ContentService) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.contentForm = this.formBuilder.group({
        title: ['', Validators.required],
        image: ['', Validators.required],
        language: ['english', Validators.required],
        userId: [params.get('user_id')!],
        description: ['', Validators.required],
      });
      console.log(this.contentForm.value);
    });
  }


  closeModal() {
    this.showSharing = false;
  }

  toggleModal(){
    this.showSharing = !this.showSharing;
  }

  openMethodologicalTest() {
    this.showMethodologicalContentPopup = !this.showMethodologicalContentPopup;
  }

  close() {this.closePopup.emit();}


  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) return;

    for (const file of Array.from(input.files)) {
      const fileIdentifier = `${file.name}-${file.size}`;

      if (
        (file.type === 'application/pdf' ||
        file.type === 'application/msword' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type === 'text/plain' ||
        file.type === 'text/html' ||
        file.type === 'application/vnd.ms-powerpoint' ||
        file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
        file.type === 'application/epub+zip' ||
        file.type === 'text/markdown') && file.size <= this.fileSize
      ) {
        if (!this.fileSet.has(fileIdentifier)) {
          this.files.push(file);
          this.fileSet.add(fileIdentifier);
        }
      } else if (file.size > this.fileSize) {
        alert('File size exceeds 10MB limit.');
      } else {
        alert('Invalid file format. Please select a PDF, Word, TXT, HTML, PowerPoint, ePub, or Markdown file.');
      }
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files && files.length) {
      for (const file of Array.from(files)) {
        console.log(`File Type: ${file.type}`);
        const fileIdentifier = `${file.name}-${file.size}`;

        if (
          (file.type === 'application/pdf' ||
          file.type === 'application/msword' ||
          file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          file.type === 'text/plain' ||
          file.type === 'text/html' ||
          file.type === 'application/vnd.ms-powerpoint' ||
          file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
          file.type === 'application/epub+zip' ||
          file.type === 'text/markdown') && file.size <= this.fileSize
        ) {
          if (!this.fileSet.has(fileIdentifier)) {
            this.files.push(file);
            this.fileSet.add(fileIdentifier);
          }
        } else if (file.size > this.fileSize) {
          alert('File size exceeds 10MB limit.');
        } else {
          alert('Invalid file format. Please drop a PDF, Word, TXT, HTML, PowerPoint, ePub, or Markdown file.');
        }
      }
    }
  }


  onDragOver(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  deleteFile(fileToDelete: File): void {
    this.files = this.files.filter(file => file !== fileToDelete);
  }

  onImageFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      console.log(file.name);
      this.contentForm!.patchValue({
        image: file
      });
    }
  }

  async uploadImage(){
    if (this.contentForm!.value['image'] instanceof File) {
      console.log("🔔UPLOAD IMAGE IS A FILE")
      const result = await this.contentService.uploadFileToUploadCare(this.contentForm!.value['image']);
      console.log(result);
      console.log(result.cdnUrl);
      this.contentForm!.patchValue({
        image: result.cdnUrl
      });
    }else{
      console.log("🔴UPLOAD IMAGE IS NOT A FILE")
    }
  }
  async uploadFiles() {
    if (this.files && this.files.length > 0) {
      for (const file of this.files) {
        if (file instanceof File) {
          console.log("🔔UPLOAD FILE IS A FILE", file.name);
          try {
            const result = await this.contentService.uploadFileToUploadCare(file);
            console.log(result);
            console.log(result.cdnUrl);
            // Assuming you want to keep the list of uploaded URLs somewhere
            this.uploadedFilesUrls.push(result.cdnUrl);
          } catch (error) {
            console.error("Error uploading file:", file.name, error);
          }
        } else {
          console.log("🔴UPLOAD FILE IS NOT A FILE", file);
        }
      }
    } else {
      console.log("🔴NO FILES TO UPLOAD");
    }
  }


  submitContent() {
    if (this.contentForm?.valid) {
      this.loadingCreatingContent = true;
      console.log('Form is valid. Proceeding with image upload...');
      this.uploadImage().then(() => {
        console.log('Image upload completed.');
        console.log('The Form data is:', this.contentForm?.value);

        this.uploadFiles().then(() => {
          console.log('File upload completed.');
          console.log('The uploaded files are:', this.uploadedFilesUrls);
          const formData = { ...this.contentForm?.value, files: this.uploadedFilesUrls };
          console.log('Form data with files:', formData);
          this.contentService.addContent(formData).subscribe(
            response => {
              console.log("🟢 The response is: ");
              console.log(response);
              this.contentId=response['body']['content_id'];
              this.loadingCreatingContent = false;
              let queryParams = { action: 'addMethodScript', contentId: this.contentId,'language':this.contentForm?.get('language')?.value};
              this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                replaceUrl: true
              });
            });
        }).catch(error => {
          console.error('Error during file upload:', error);
          this.loadingCreatingContent = false;
        });
      }).catch(error => {
        console.error('Error during image upload:', error);
        this.loadingCreatingContent = false;
      });
    } else {
      console.warn('Form is invalid. Please correct the errors.');
    }
  }



  //  submitContent() {
  //   if (this.contentForm?.valid) {
  //     this.loadingCreatingContent = true;
  //     this.uploadImage().then(() => {
  //       const formData = new FormData();
  //       Object.keys(this.contentForm!.controls).forEach(key => {
  //         const controlValue = this.contentForm!.get(key)?.value;
  //         if (controlValue !== null) {
  //           formData.append(key, controlValue);
  //         }
  //       });
  //       this.files.forEach((file, index) => {
  //         formData.append(`files`, file, file.name);
  //       });
  //       formData.forEach((value, key) => {
  //         console.log(key + ', ' + value);
  //       });
  //       this.contentService.addContent(formData).subscribe(
  //         response => {
  //           console.log("🟢 The response is: ");
  //           console.log(response);
  //           this.contentId=response['body']['content_id'];
  //           this.loadingCreatingContent = false;
  //           let queryParams = { action: 'addMethodScript', contentId: this.contentId,'language':this.contentForm?.get('language')?.value};
  //           this.router.navigate([], {
  //             relativeTo: this.activatedRoute,
  //             queryParams: queryParams,
  //             replaceUrl: true
  //           });
  //         });
  //     });
  //   }
  // }

}
