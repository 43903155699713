import { Component, ElementRef, HostListener, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Content } from 'src/app/model/content';
import { ContentService } from 'src/app/services/content.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Auth } from '@angular/fire/auth';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-company-home-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.css'],
})
export class HomeViewComponent implements OnInit {
  userId: String | undefined;
  route = inject(ActivatedRoute);
  showShareButton: boolean = false;
  contents: Set<Content> = new Set();
  showPopUp = false;
  showEditContentPopup = false;
  selectedContent_id: any;
  loadingPresentation: boolean = true;
  showModal:boolean = false;
  lang: string = 'auto';
  user:any;
  invitedContents: Set<Content> = new Set();
  selectedOption = 'ES';
  dropdownOpen = false;
  showMisTutores: boolean = true;
  showTutoresCompartidos: boolean = false;
  getContentUpdates:boolean = true;
  private queryParamsSubscription: Subscription | undefined;
  intervalId: any;

  constructor(private contentService: ContentService, private userService: UserService, private translateService: TranslateService,
     private afAuth:Auth,private router: Router, private location: Location, private elRef: ElementRef,private activatedRoute: ActivatedRoute) {
      this.translateService.addLangs(['en', 'es', 'fr']);
      this.translateService.setDefaultLang('auto');
      const browserLang = this.translateService.getBrowserLang() || 'es';
      this.translateService.use(browserLang.match(/en|es|fr/) ? browserLang : 'es');
  }

  ngOnInit(): void {
    const browserLang = window.navigator.language.split('-')[0];
    this.translateService.setDefaultLang(browserLang);
    this.translateService.use(browserLang);
    if (this.lang === 'auto') {
      this.lang = browserLang;
    }

    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        console.log('Usuario autenticado:', user.email);
        this.user = user;
        this.getUserInvitations();
      } else {
        console.log('Usuario no autenticado');
      }
    });


    if (this.lang === 'auto') {
      this.lang = browserLang;
    }
    this.route.paramMap.subscribe((params) => {
      this.userId = params.get('user_id')!;
      console.log(this.userId);
       console.log('Params:', params);
    });

    this.getUserTutors();

   this.intervalId = setInterval(() => {
    console.log('Obteniendo los contenidos del usuario');
    if(this.getContentUpdates){
      this.handleContents();
    }
  }, 20000);


    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(params => {
      if (params['action'] != undefined) {
        this.showPopupView(true,params);
      }
    });

  }


  ChangeLang(event: any) {
    const selectedLanguage = event.target.value;

    if (selectedLanguage === 'auto') {

      this.translateService.use(window.navigator.language.split('-')[0]);

    } else {

      this.translateService.use(selectedLanguage);

    }

    localStorage.setItem('lang', selectedLanguage);
  }


  closeModal() {
      this.showModal = false;
    }

    toggleModal(){
    this.showModal = !this.showModal;
    }


  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.dropdownOpen = false;
  }

  showPopupView(show:boolean,params?:any){
    console.log('Show popup:', show);
    this.showPopUp = show;
    this.loadingPresentation = false;
    let queryParams = {};
    if (this.showPopUp) {
      queryParams = params;
    }
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      replaceUrl: true
    });
  }

  shareContent(content: any, event: any) {
    console.log("SHARE CONTENT");
    console.log(content);
    this.selectedContent_id = content._id;
    event.stopPropagation();
    this.toggleModal();
  }

  toggleEditContentPopup() {
    this.showEditContentPopup = !this.showEditContentPopup;
    console.log('Clicked showEditContentPopup:', this.showEditContentPopup);
  }

  goToClassroom(content: any) {
    this.router.navigate(['/class',this.userId, content._id,true]);
  }



  // editContent(content: any): void {
  //   console.log('Edit content');
  //   this.selectedContent_id = content._id;
  //   this.showEditContentPopup = !this.showEditContentPopup;
  // }



  getUserTutors(){
    this.contentService.getAllUserContents(this.userId).subscribe((response) => {
      console.log(response);
      console.log(response.length);
      this.contents.clear();
      for (const item of response) {
        const content = new Content().fromJson(item);
        this.contents.add(content);
      }
      this.loadingPresentation = false;
    });
  }

  getUserInvitations(){
    this.contentService.getAllUserInvitationContents(`${this.user.email}`).subscribe({
          next: (response) => {
            console.log('Contenidos de invitación:', response.length, ': ', response);
            for (let i = 0; i < response.length; i++) {
              this.invitedContents.add(new Content().fromJson(response[i]));
            }
            this.loadingPresentation = false;
          },
          error: (error) => {
            console.error('Error al obtener los contenidos de invitación:', error);
          }
        });
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      const inputElement = event.target as HTMLInputElement;
      const searchText = inputElement.value.trim();
      console.log('Search:', searchText);
      this.searchContent(searchText);
    }
  }


  handleContents(){
    if (this.showMisTutores){
      this.getUserTutors();
    }else if(this.showTutoresCompartidos){
      this.getUserInvitations();
    }
  }


  searchContent(query: string) {
    console.log('Search:', query);
    if(query.length == 0){
      this.getContentUpdates = true;
    }else{
      this.getContentUpdates = false;
    }
    if (this.showMisTutores) {
      console.log('Searching content:', query);
      this.contentService.searchContent(this.userId, query).subscribe((response) => {
        console.log('Search response:', response);
        this.contents.clear();
        response.forEach((item: any) => this.contents.add(new Content().fromJson(item)));
      });
    } else if(this.showTutoresCompartidos){
      console.log('Searching compartidos:', query);
      this.contentService.searchInvitations(this.user.email, query).subscribe((response) => {
        console.log('Search response:', response);
        this.invitedContents.clear();
        response.forEach((item: any) => this.invitedContents.add(new Content().fromJson(item)));
      });
    }
  }

}
