<div *ngIf="loadingCreatingContent" class="flex flex-col justify-center items-center h-screen">
  <div class="relative w-16 h-16">
    <div class="absolute inset-0 w-16 h-16 border-6 border-blue-500 rounded-full animate-pulse"></div>
    <div class="absolute inset-0 w-16 h-16 border-6 border-transparent border-t-blue-500 rounded-full animate-spin"></div>
  </div>
  <div class="text-2xl mt-5 text-blue-500 font-sans uppercase text-center">Loading...</div>
</div>

<div class="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
  <div class="w-full p-4 border border-gray-300 rounded-lg">
    <div class="border-2 border-dashed border-gray-300 p-4 h-52 flex flex-col justify-center items-center text-center cursor-pointer"
         (click)="fileInput.click()"
         (drop)="onDrop($event)"
         (dragover)="onDragOver($event)">
      <p class="m-0 font-bold">
        🗳️ <strong>Upload or drag a file</strong> <br>
        <span>(PDF, DOCX, TXT, HTML, PPTX, PPT, EPUB, MD)</span> <br>
        <span>File size limit: 10MB</span><br>
        <span>*Extracting images from files works only with PDFs</span>
      </p>
      <input type="file" #fileInput
             (change)="onFileSelected($event)"
             accept=".pdf, .docx, .txt, .html, .pptx, .ppt, .epub, .md" class="hidden">
    </div>
    <div class="mt-4">
      <div class="flex justify-between items-center mb-2" *ngFor="let file of files">
        <div class="flex items-center">
          <span class="bg-red-500 text-white p-1 mr-2">{{ file.type }}</span>
          <span>{{ file.name }}</span>
        </div>
        <button class="text-red-500 cursor-pointer text-xl" (click)="deleteFile(file)">🗑️</button>
      </div>
    </div>
  </div>

  <div class="w-full p-4 border border-gray-300 rounded-lg">
    <form [formGroup]="contentForm!">
      <div class="mb-5">
        <label for="title" class="block mb-1 font-bold text-[#C6AC8F]">Title</label>
        <input type="text" id="title" formControlName="title" class="w-full p-2 rounded border border-gray-300 bg-[#e9e7dc]">
      </div>
      <div class="mb-5">
        <label for="imageUrl" class="block mb-1 font-bold text-[#C6AC8F]">Image (Url or File)</label>
        <input type="text" id="imageUrl" formControlName="image" class="w-full p-2 rounded border border-gray-300 bg-[#e9e7dc]">
        <div class="relative flex items-center w-full max-w-xs p-2 mt-2 border-2 border-dashed border-gray-300 rounded">
          <span class="bg-[#22333B] text-white p-2 mr-2 rounded">Choose files</span>
          <span class="text-gray-500">or drag and drop files here</span>
          <input type="file" accept=".png, .jpg, .jpeg" (change)="onImageFileSelected($event)" multiple class="absolute inset-0 w-full h-full opacity-0 cursor-pointer">
        </div>
      </div>
      <div class="mb-5">
        <label for="language" class="block mb-1 font-bold text-[#C6AC8F]">Language</label>
        <select id="language" formControlName="language" class="w-full p-2 rounded border border-gray-300 bg-[#e9e7dc]">
          <option value="english">English</option>
          <option value="spanish">Spanish</option>
        </select>
      </div>
      <div class="mb-5">
        <label for="description" class="block mb-1 font-bold text-[#C6AC8F]">Description</label>
        <textarea id="description" formControlName="description" class="w-full p-2 rounded border border-gray-300 bg-[#e9e7dc] h-40 resize-none"></textarea>
      </div>
    </form>
  </div>
</div>

<div class="flex flex-col h-full p-4">
  <div class="mt-auto">
    <button (click)="submitContent()" class="w-full p-4 bg-[#C6AC8F] text-white rounded-lg cursor-pointer text-base">Next Step</button>
  </div>
</div>


