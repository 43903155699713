<div class="flex flex-col bg-beige p-1" style="height: 100vh;">
  <div
    class="flex items-center justify-between bg-beige p-4"
    style="height: 7vh;"
  >
    <h1
      class="text-4xl font-black text-dark-blue"
      style="font-family: Fredoka, sans-serif;"
    >
      Learnable
    </h1>
  </div>
  <div class="flex-grow flex">
    <div
      class="w-[100%] h-full bg-beige rounded-lg flex flex-col items-center justify-center"
    >
      <div class="bg-blue h-[66vh] w-[95%]" #presentationcontainer>
        <div class="inner_html">
          <img
            class="w-[50vw] h-[50vh] mr-2 ml-5"
            src="./assets/images/learn.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="bg-blue h-[66vh] w-[95%] mt-12 mr-10" #presentationcontainer>
      <div class="inner_html">
        <div class="bg-beige p-6 sm:p-8">
          <h1
            class="text-4xl font-bold leading-tight tracking-tight text-gray-900 text-center"
            style="font-family: 'Fredoka One', sans-serif;"
          >
            Login
          </h1>
          <br /><br /><br /><br />
          <form [formGroup]="formLogin" (ngSubmit)="onSubmit()">
            <input
              class="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
              type="email"
              placeholder="Email"
              formControlName="email"
            />

            <input
              class="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
              type="password"
              placeholder="Password"
              formControlName="password"
            />

            <div class="mt-4 flex items-center justify-between">
              <!-- Columna 1 -->
              <div></div>

              <!-- Columna 2 -->
              <div class="ml-2">
                <a href="#" class="text-sm text-[#b38758]">Forgot Password?</a>
              </div>
            </div>

            <button
              type="submit"
              class="mt-5 tracking-wide font-semibold bg-[#c59c6f] hover:bg-[#b38758] text-gray-100 w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
            >
              <svg
                class="w-6 h-6 -ml-2"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                <circle cx="8.5" cy="7" r="4" />
                <path d="M20 8v6M23 11h-6" />
              </svg>
              <span class="ml-3">
                Login
              </span>
            </button>
          </form>
          <div class="ml-2 text-center">
            <p class="text-sm font-bold text-gray-900 mt-2">Conectar con:</p>
          </div>
          <div class="flex justify-center items-center">
            <div class="flex items-center space-x-4 mt-2 mr-25">
              <button
                class="bg-transparent hover:bg-transparent text-white p-2 transform hover:scale-105 transition duration-300"
                (click)="signUpWithGoogle()"
              >
                <img
                  src="./assets/images/google_icon.png"
                  alt="Google Icon"
                  class="h-8 w-8"
                />
              </button>

              <button
                class="bg-transparent hover:bg-transparent text-white p-2 transform hover:scale-105 transition duration-300"
              >
                <img
                  src="./assets/images/linkedin_icon.png"
                  alt="LinkedIn Icon"
                  class="h-8 w-8"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
