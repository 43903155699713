<div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
  <div class="w-[80vw] h-[90vh] bg-gray-50 p-5 rounded-lg shadow-md grid grid-rows-[auto_1fr_auto] gap-2.5">
    <div class="flex justify-end p-2.5">
      <button (click)="close()" class="text-red-500 bg-transparent border-none p-2 cursor-pointer leading-tight">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <div class="overflow-auto">

      <div *ngIf="params['action'] === 'addContent'">
        <app-add-content-view (closePopup)="close()" ></app-add-content-view>
      </div>

      <div *ngIf="params['action'] === 'addMethodScript'">
        <app-create-methodological-method-view (closePopup)="close()"></app-create-methodological-method-view>
      </div>

      <div *ngIf="params['action'] === 'createContentResources'">
        <app-create-content-resources-view (closePopup)="close()"></app-create-content-resources-view>
      </div>

      <div *ngIf="params['action'] === 'editPresentation'">
       <app-edit-presentation-view (closePopup)="close()" ></app-edit-presentation-view>
      </div>


      <div *ngIf="params['action'] === 'editContent'">
        <app-edit-cotent-view (closePopup)="close()" ></app-edit-cotent-view>
       </div>

    </div>
  </div>
</div>
