<div class="popup-body">
  <div class="left-side">
    <div class="share-container">
      <span class="share-label">Image Course:</span>
    </div>
    <img [src]="this.current_content?.image" class="content-cover" />
  </div>

  <div class="right-side">
    <div class="form-container">
      <form>
        <div class="form-group">
          <label for="courseId">Course Id</label>
          <input
            type="text"
            id="courseId"
            placeholder="Enter course ID"
            [value]="this.current_content?._id"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="courseName">Title Course</label>
          <input
            type="text"
            id="courseName"
            placeholder="Enter course name"
            [value]="this.current_content?.title"
          />
        </div>
        <div class="form-group">
          <label for="name">Description</label>
          <input
            type="text"
            id="name"
            placeholder="Enter name"
            [value]="this.current_content?.description"
          />
        </div>
        <div class="flex gap-3 flex-col">
          <button (click)="testContent()" class="bg-gray-800/45 p-2 rounded-xl">
            ⚡️{{'Test'|translate}}⚡️
          </button>
          <!-- <button
            (click)="this.showPopupView({'action':'editPresentation','contentId':this.contentId})"
            class="bg-blue-800/35 p-2 rounded-xl"
          >
            🏞️Edit presentation
          </button>
          <button
            (click)="deleteContent()"
            class="bg-red-500/30 p-2 rounded-xl"
          >
            ❌{{'Delete'|translate}}
          </button> -->
        </div>
      </form>
    </div>
  </div>
</div>
