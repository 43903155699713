import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './user-routing.module';
import { HomeViewComponent } from './home-view/home-view.component';
import { AddContentViewComponent } from '../Content/add-content-view/add-content-view.component';
import { ClassViewComponent } from './class-view/class-view.component';
import { CreateMethodologicalMethodViewComponent } from '../Content/create-methodological-method-view/create-methodological-method-view.component';
import { EditContentViewComponent } from '../Content/edit-content-view/edit-content-view.component';
import { ProfileComponent } from './profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedContentComponent } from '../Content/shared-content/shared-content.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PopUpViewComponent } from '../pop-up-view/pop-up-view.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EditPresentationViewComponent } from '../Content/edit-presentation-view/edit-presentation-view.component';
import { ContentCardComponent } from '../content-card/content-card.component';
import { CreateContentResourcesViewComponent } from '../Content/create-content-resources-view/create-content-resources-view.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Importa BrowserAnimationsModule
import { BankImagesComponent } from '../Content/bank-images/bank-images.component';
export function HttpLoaderFactory(http:HttpClient){
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    HomeViewComponent,
    AddContentViewComponent,
    ClassViewComponent,
    CreateMethodologicalMethodViewComponent,
    EditContentViewComponent,
    ProfileComponent,
    SharedContentComponent,
    PopUpViewComponent,
    EditPresentationViewComponent,
    ContentCardComponent,
    CreateContentResourcesViewComponent,
    BankImagesComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule,    BrowserAnimationsModule // Agrega BrowserAnimationsModule a los imports del módulo
 ,
    TranslateModule.forRoot(
      {
        defaultLanguage:'en-US',
        loader:{
          provide:TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps:[HttpClient]
        }
      })],
  exports: [
    HomeViewComponent,
    AddContentViewComponent,
    ClassViewComponent,
    CreateMethodologicalMethodViewComponent,
    EditContentViewComponent,
    ProfileComponent,
    UserRoutingModule,
    BankImagesComponent

  ],
})
export class UserModule {}
