import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginViewComponent } from './login-view/login-view.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RegisterViewComponent } from './register-view/register-view.component';



@NgModule({
  declarations: [
    LoginViewComponent,
    RegisterViewComponent,
  ],
  imports: [
    ReactiveFormsModule
  ],
  exports:[
    ReactiveFormsModule,
    LoginViewComponent,
    RegisterViewComponent,
  ],
  providers: [AuthRoutingModule],
  schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ]

})
export class AuthModule { }
