import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-welcome-view',
  templateUrl: './welcome-view.component.html',
  styleUrls: ['./welcome-view.component.css']
})
export class WelcomeViewComponent implements OnInit {

  lang: string = 'auto';
  constructor( private translateService: TranslateService) {

    this.translateService.addLangs(['en', 'es', 'fr']);
    this.translateService.setDefaultLang('auto');

    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      this.translateService.use(storedLang);
    } else {
      const browserLang = this.translateService.getBrowserLang() || 'es';
      this.translateService.use(browserLang.match(/en|es|fr/) ? browserLang : 'es');
    }

   }

   ngOnInit(): void {
    const browserLang = window.navigator.language.split('-')[0];
    this.translateService.setDefaultLang(browserLang);

    if (this.lang === 'auto') {
      this.lang = browserLang;
    }
  }


}
