<div class="w-full h-[70vh] flex flex-col">

  <div class="flex-[1]">
    <h1 class="text-left font-bold text-3xl text-[#22333B] mb-4">{{ 'Crear Recursos'|translate }} 🏞️ 🎭 🔊</h1>
  </div>

  <div class="flex-[2]">
    <form [formGroup]="formReg!">
      <div class="mb-5 flex items-center">
        <label for="speech" class="w-64 mr-2.5 font-bold text-[#C6AC8F]">{{ 'Longitud del discurso por diapositiva'|translate }} (s)</label>
        <input type="number" id="speech" class="w-[10vw] p-2.5 rounded-lg border border-gray-300 text-lg bg-[#e9e7dc]"
          placeholder="" min="5"
          max="60" step="1"
          formControlName="speech_len"
          (keyup.enter)="!validateForm()"
          >
      </div>
      <div *ngIf="formReg.get('speech_len')?.invalid && formReg.get('speech_len')?.touched">
        <p class="text-red-500">5-60 seg.</p>
      </div>
    </form>



  </div>

  <div class="flex-[4]">

    <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; flex-wrap: wrap; margin-top: 40px; text-align: center;">
      <div *ngFor="let theme of themes"
           style="padding: 10px; margin-right: 8px; cursor: pointer; border-radius: 10%; width: 250px; height: 150px; margin-right: 10px;"
           [ngStyle]="{'border': theme === selectedTheme ? '3px solid #C6AC8F' : 'none'}"
           (click)="selectTheme(theme)">
        <img [src]="theme.image"
             style="width: 100%; height: 100%; margin-right: 10px;">
      </div>
    </div>


  </div>

  <div class="flex-[1]">
      <button
        class="w-full py-4 px-8 bg-[#C6AC8F] text-white border-none cursor-pointer text-base"
        (click)="saveContentResources(); !validateForm();">
        {{ 'Guardar'|translate }}
      </button>
  </div>
</div>



















<!--

<div class="popup-body">
    <div class="form-container">
      <h1>Create Content Resources 📚</h1>

      <form [formGroup]="formReg!">
        <div class="form-group" >
          <label for="manySlides" class="label">How many Slides</label>
          <input type="number" id="manySlides" class="input"
            placeholder=""
            min="4" max="15"
            step="1" formControlName="slides"
            (keyup.enter)="!validateForm()"
            >
        </div>
        <div *ngIf="formReg.get('slides')?.invalid && formReg.get('slides')?.touched">
          <p class="text-red-500">Es un valor entre 4 y 15.</p>
        </div>
        <div class="form-group">
          <label for="speech" class="label">How long speech per slides (s)</label>
          <input type="number" id="speech" class="input"
            placeholder="" min="5"
            max="60" step="1"
            formControlName="speech_len"
            (keyup.enter)="!validateForm()"
           >
       </div>

       <div *ngIf="formReg.get('speech_len')?.invalid && formReg.get('speech_len')?.touched">
        <p class="text-red-500">El audio debe estar entre 5 y 60 segundos.</p>
      </div>
    </form>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: start; align-items: start; flex-wrap: wrap; margin-top: 40px; text-align: center;">
      <div *ngFor="let theme of themes"
           style="padding: 10px; margin-right: 8px; cursor: pointer; border-radius: 10%; width: 180px; height: 120px; margin-right: 10px;"
           [ngStyle]="{'border': theme === selectedTheme ? '3px solid #C6AC8F' : 'none'}"
           (click)="selectTheme(theme)">
        <img [src]="theme.image"
             style="width: 100%; height: 100%; margin-right: 10px;">
      </div>
  </div>
</div>




 -->
