import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Content } from 'src/app/model/content';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-edit-cotent-view',
  templateUrl: './edit-content-view.component.html',
  styleUrls: ['./edit-content-view.component.css']
})
export class EditContentViewComponent {

  @Output() closePopup = new EventEmitter<void>();
  user_id: string| undefined;
  contentId: string| undefined;
  current_content:Content | undefined;

  close() {this.closePopup.emit();}


  constructor(private router: Router,private contentService: ContentService,
    private activatedRoute: ActivatedRoute,) {}


  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      console.log(params);
      this.user_id=params.get('user_id')!;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      console.log("I'm in the popup:", params);
      this.contentId=params['contentId']!;
  });

  this.contentService.GetContentResources(this.contentId!).subscribe((res) => {
    this.current_content=new Content().fromJson(res);
  });

  }


  copyShareId(): void {
    const textArea = document.createElement('textarea');
    textArea.value = this.contentId!;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    console.log('Share ID copied to clipboard');
  }


  testContent() {
    console.log('🔎search');
    console.log(`Searching for course with ID: ${this.contentId!}`);
    this.router.navigate(['/class',"0", this.contentId!,false]);
  }

  deleteContent() {
    this.contentService.deleteContent({"content_id": this.contentId!,"user_id":this.user_id!}).subscribe( (res) => {
      console.log('Content deleted');
      this.close();
    });
  }

  showPopupView(params?:any){
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      replaceUrl: true
    });
  }


}
