import { Component, EventEmitter, Output } from '@angular/core';
import {ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-pop-up-view',
  templateUrl: './pop-up-view.component.html',
  styleUrls: ['./pop-up-view.component.css']
})
export class PopUpViewComponent {
  @Output() closePopup = new EventEmitter<void>();
  params: any;
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
        this.params = params;
        console.log("I'm in the popup:", params);
    });
  }

  close() {this.closePopup.emit();}


}
