import { Component, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit{

  userId: String | undefined;
  route = inject(ActivatedRoute);
  selectedOption = 'ES';
  dropdownOpen = false;
  user: any;
  lang: string = 'auto';
  formReg!: FormGroup;
  constructor(private translateService: TranslateService, private auth:Auth, private location: Location,private formBuilder: FormBuilder) {

    this.translateService.addLangs(['en', 'es', 'fr']);
    this.translateService.setDefaultLang('auto');

    const browserLang = this.translateService.getBrowserLang() || 'es';
    this.translateService.use(browserLang.match(/en|es|fr/) ? browserLang : 'es');

   }

  ngOnInit(): void {

    this.route.paramMap.subscribe((params) => {
      this.userId = params.get('user_id')!;
      console.log(this.userId);
    });

    const browserLang = window.navigator.language.split('-')[0];
    this.translateService.setDefaultLang(browserLang);
    this.translateService.use(browserLang);
    if (this.lang === 'auto') {
      this.lang = browserLang;
    }
    this.auth.onAuthStateChanged(userData =>{
      this.user = userData;
    })


    this.formReg = this.formBuilder.group({
      slides: ['', Validators.required],
      speech_len: ['', Validators.required],
    });
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.dropdownOpen = false;
  }

  goBack(): void {
    this.location.back();
  }

  showEditNameModal: boolean = false;
    editedName: string = '';

    openEditNameModal() {
        this.showEditNameModal = true;
    }

    closeEditNameModal() {
        this.showEditNameModal = false;
    }

    saveName() {
        // Lógica para guardar el nuevo nombre
        // Por ahora, simplemente cerramos el modal
        this.closeEditNameModal();
    }

  ChangeLang(event: any) {
    const selectedLanguage = event.target.value;

    if (selectedLanguage === 'auto') {

      this.translateService.use(window.navigator.language.split('-')[0]);

    } else {

      this.translateService.use(selectedLanguage);

    }

    localStorage.setItem('lang', selectedLanguage);
  }

}


// export class ProfileComponent implements OnInit{

//   lang: string = 'auto';
//   constructor(private translateService: TranslateService) {

//     this.translateService.addLangs(['en', 'es', 'fr']); // Idiomas disponibles
//     this.translateService.setDefaultLang('en'); // Idioma predeterminado

//     const browserLang = this.translateService.getBrowserLang() || 'es'; // Si el idioma del navegador es indeterminado, se usa 'es' (español) como idioma predeterminado
//     this.translateService.use(browserLang.match(/en|es|fr/) ? browserLang : 'es'); // Usa el idioma del navegador si está disponible, de lo contrario, usa el español

//    }

//   ngOnInit(): void {
//     const browserLang = window.navigator.language.split('-')[0];
//     this.translateService.setDefaultLang(browserLang);
//     this.translateService.use(browserLang);
//     if (this.lang === 'auto') {
//       this.lang = browserLang;
//     }
//   }

//   ChangeLang(event: any) {
//     const selectedLanguage = event.target.value;

//     if (selectedLanguage === 'auto') {

//       this.translateService.use(window.navigator.language.split('-')[0]);

//     } else {

//       this.translateService.use(selectedLanguage);

//     }

//     localStorage.setItem('lang', selectedLanguage);
//   }

//   // lang:string ='';
//   // constructor(private translateService:TranslateService){}

//   // ngOnInit(): void {
//   //  // this.lang = localStorage.getItem('lang')||'en';
//   // }

//   // ChangeLang(event:any){
//   //   const selectedLanguage = event.target.value;
//   //   localStorage.setItem('lang',selectedLanguage);
//   //   //alert(selectedLanguage);
//   //   this.translateService.use(selectedLanguage);
//   // }

// }

//---------------------------------------------
// import { Component, inject } from '@angular/core';
// import { ActivatedRoute } from '@angular/router';

// @Component({
//   selector: 'app-profile',
//   templateUrl: './profile.component.html',
//   styleUrls: ['./profile.component.css']
// })
// export class ProfileComponent {
//   userId: String | undefined;
//   route = inject(ActivatedRoute);
//   constructor() {}

//   ngOnInit(): void {
//     this.route.paramMap.subscribe((params) => {
//       this.userId = params.get('user_id')!;
//       console.log(this.userId);
//     });
//   }
// }
