<div class="bg-gray-100 w-full mx-4 p-4 rounded-xl md:w-full lg:w-2/3 xl:w-1/2 xl:ml-20">
  <!-- MODAL HEADER -->
  <div class="flex justify-between items-center border-b border-gray-200 py-3 ml-2">
    <div class="flex items-center justify-center">
      <button *ngIf="showMessageBox" class="mr-2" (click)="toggleMessageBox()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current text-gray-800">
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
        </svg>
      </button>
      <p class="text-3xl font-medium text-gray-800">
        {{ 'Compartir' | translate }}
      </p>
    </div>

    <div class="bg-gray-300 hover:bg-gray-500 cursor-pointer hover:text-gray-300 font-sans text-gray-500 w-8 h-8 flex items-center justify-center rounded-full" (click)="closeModal()">
      <i class="fas fa-times"></i>
    </div>
  </div>

  <div class="my-4">
    <!-- BOX LINK -->
    <div class="border-2 border-gray-200 flex flex-wrap items-center mt-4 py-2 rounded-2xl" *ngIf="!showMessageBox">
      <div class="flex-grow flex items-center">
        <input class="flex-grow outline-none bg-transparent m-3" type="email" placeholder="{{ 'Añadir personas, digite el email' | translate }}" [(ngModel)]="email" (keyup.enter)="addEmail()" />
      </div>
    </div>
  
    <!-- New Message Textbox with Back Arrow -->
    <div *ngIf="showMessageBox" class="mt-4">
      <div class="border-2 border-gray-200 flex flex-wrap items-center mt-4 py-2 rounded-2xl">
        <div class="flex-grow flex items-center flex-wrap">
          <ng-container *ngFor="let email of emails">
            <div class="flex items-center bg-gray-200 rounded-full px-3 py-1 mr-2 mb-2">
              <span>{{ email }}</span>
              <button (click)="removeEmail(email)" class="text-red-500 hover:text-red-700 ml-2">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </ng-container>
          <input class="flex-grow outline-none bg-transparent m-3" type="email" placeholder="{{ 'Añadir personas, digite el email' | translate }}" [(ngModel)]="email" (keyup.enter)="addEmail()" />
        </div>
      </div>
      
      <!-- Input para mensaje aqui ira el mensaje que se le adicione a los correos -->
      <input class="flex-grow border-2 border-gray-200 rounded-2xl p-2 outline-none w-full mb-4" type="text" placeholder="{{ 'Mensaje' | translate }}" [(ngModel)]="message" style="height: 100px;" />
    
      <!-- Botones -->
      <div class="flex justify-between items-center mt-4">
        <button class="bg-indigo-500 text-white rounded text-sm py-2 px-4 ml-2 flex items-center hover:bg-indigo-600" (click)="copyShareUrl()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current text-white mr-2">
            <path d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z"/>
            <path d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z"/>
          </svg>
        </button>
        <button class="bg-green-500 text-white rounded text-sm py-2 px-5 ml-2 mr-2 hover:bg-green-600" (click)="sendInvitationEmail()">
          {{ 'Invite' | translate }}
        </button>
      </div>
    </div>
    
    <!-- List of Emails and Action Buttons -->
    <div *ngIf="emails.size > 0 && !showMessageBox" class="mt-2">
      <p class="text-2xl font-medium text-gray-800 m-2">
        {{ 'Personas con acceso' | translate }}
      </p>
      <ul class="flex flex-wrap">
        <li *ngFor="let email of emails" class="mr-2 mb-2">
          {{ email }}
          <button class="remove-button" (click)="removeEmail(email)">
            <i class="fas fa-times"></i>
          </button>
        </li>
      </ul>
      <button class="bg-green-500 text-white rounded text-sm py-2 px-5 ml-2 mr-2 hover:bg-green-600" (click)="sendInvitationEmail()">
        {{ 'Invite' | translate }}
      </button>
    </div>
  
   
<!-- General Access Section -->
<p class="text-xl text-gray-800 mt-4 ml-2" *ngIf="!showMessageBox">
  {{ 'Acceso general' | translate }}
</p>
<div class="flex justify-between items-center mt-4 py-2" *ngIf="!showMessageBox">
  <button class="bg-indigo-500 text-white rounded text-sm py-2 px-4 ml-2 flex items-center hover:bg-indigo-600" (click)="copyShareUrl()">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current text-white mr-2">
      <path d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z"/>
      <path d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z"/>
    </svg>
    {{ 'Copy' | translate }}
  </button>
</div>
</div>
</div>
