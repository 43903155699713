<div class="flex flex-col bg-beige p-1" style="height: 100vh;">

  <div class="flex items-center justify-between bg-beige p-4" style="height: 7vh;">
    <h1 class="text-4xl font-black text-dark-blue" style="font-family: Fredoka, sans-serif;">Learnable</h1>
  </div>

  <div class="flex-grow flex flex-col items-center justify-center">
    <div class="bg-blue w-[45%] md:w-[20%] h-auto md:h-[20vh] mt-8 mb-2 md:mb-0 flex items-center justify-center">
      <div class="inner_html">
        <img class="w-full h-auto md:h-[22vh]" src="./assets/images/learn.png" alt="">
      </div>
    </div>
    <div class="bg-blue w-[95%] md:w-[50%] h-auto md:h-[50vh] mt-8 md:mt-0 ml-0 md:ml-10">
      <div class="inner_html bg-beige p-6 sm:p-8 flex flex-col justify-center items-center">
        <h1 class="text-4xl font-bold leading-tight tracking-tight text-gray-900 text-center mb-2" style="font-family: 'Fredoka One', sans-serif;">
          Sign Up
        </h1>
        
        <form [formGroup]="formReg!" (ngSubmit)="onSubmit()" class="space-y-4 md:space-y-6 w-full max-w-lg" action="#">
          <div>
            <input
              type="name"
              name="name"
              id="name"
              formControlName="name"
              class="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
              placeholder="Name"
              required=""
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              id="email"
              formControlName="email"
              class="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
              placeholder="Email"
              required=""
            />
          </div>
          <div class="flex flex-col md:flex-row gap-2 w-full">
            <div>
              <input
                type="password"
                name="password"
                id="password"
                formControlName="password"
                placeholder="Password"
                class="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                required=""
              />
            </div>
            <div>
              <input
                type="password"
                name="confirm_password"
                id="confirm_password"
                formControlName="confirm_password"
                placeholder="Confirm Password"
                class="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                required=""
              />
            </div>
          </div>
          <button
            type="submit"
            class="w-full text-white bg-[#c59c6f] hover:bg-[#b38758] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-md"
          >
            Create Account
          </button>
          <!-- Otras opciones (comentadas) si lo deseas -->
        </form>
      </div>
    </div>
  </div>
</div>
